import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { nonNegativeAmount } from '../../redux/apiHelper';
import { getCurrency } from '../../redux/currencies/currencyActions';
import { addInternalOrder, clearData } from '../../redux/internalOrder/internalOrderActions';
import { getAccount } from '../../redux/account/accountActions';
import { calculateFee, toFixedAndTrim } from './helper/helper';
import AmountSelectionButtons from './Components/AmountSelectionButtons';
import ConfirmationButton from './Components/ConfirmationButton';
import ShowExchangeInfo from './Components/ShowExchangeInfo';
import ExchangeBlock from './Components/ExchangeBlock';
import FullPageLoader from '../../components/FullPageLoader/fullPageLoader';
import {
  selectConversionFromAmount,
  selectConversionFromCoin,
  selectUserCoinBalance,
  selectConversionToCoin,
  selectExchangeRates,
  selectIsDataReady,
  selectIsExchangeRatesLoading,
} from '../../redux/exchange/exchangeSelectors';
import {
  setIsDataReady,
  setConversionFromCoin,
  setConversionToCoin,
  setConversionFromAmount,
  setConversionToAmount,
  setConversionFromAmountErr,
  setConversionToAmountErr,
  setUserCoinBalance,
  setExchangeRate,
  updateUserBalance,
  updateExchangeRates,
} from '../../redux/exchange/exchangeActions';
import ConfirmationModal from './Components/ConfirmationModal';
import styles from './style.module.css';

function ExchangeStarbitrex() {
  const dispatch = useDispatch();

  const { symbol } = useParams();
  const { t } = useTranslation();

  const userId = useSelector((state) => state.user?.user?._id);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const success = useSelector((state) => state.internalOrders?.success);
  const isCreatingExchangeOrder = useSelector((state) => state.internalOrders?.isLoading);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);

  const isDataReady = useSelector(selectIsDataReady);
  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const conversionToCoin = useSelector(selectConversionToCoin);
  const conversionFromAmount = useSelector(selectConversionFromAmount);
  const userCoinBalance = useSelector(selectUserCoinBalance);
  const isExchangeRatesLoading = useSelector(selectIsExchangeRatesLoading);
  const exchangeRates = useSelector(selectExchangeRates);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleShowConfirmation = () => setShowConfirmation(true);

  // init effect: fetching currencies and amounts (balances) data
  useEffect(() => {
    dispatch(getCurrency());
    if (userId) dispatch(getAccount(userId));
  }, [userId]);

  // after init effect: 
  //   if all dependencies are loaded
  //   then update user balance and fetch exchange-rates
  useEffect(async () => {
    const exchangeToSymbol = symbol === 'USDT' ? 'BTC' : 'USDT';

    if (!isDataReady && currencyData?.length && amounts?.length) {
      const exchangeFromCoin = currencyData.find((currency) => currency.symbol === symbol);
      const exchangeToCoin = currencyData.find((currency) => currency.symbol === exchangeToSymbol);
  
      dispatch(updateUserBalance(exchangeFromCoin));
      dispatch(updateExchangeRates(exchangeFromCoin));

      dispatch(setConversionFromCoin(exchangeFromCoin));
      dispatch(setConversionToCoin(exchangeToCoin));

      dispatch(setIsDataReady(true));
    }
  }, [isDataReady, currencyData, amounts]);

  const handleUserInput = (value) => {
    value = parseFloat(value);

    if (value) {
      const outOfMinLimit = value === 0 || value < conversionFromCoin.minAmount;
      const outOfMaxLimit = value > conversionFromCoin.maxAmount;
      const insufficientFundsWarning = value > parseFloat(userCoinBalance);

      // handle empty field input
      value = !Number.isNaN(value) ? toFixedAndTrim(value, conversionFromCoin.decimalPrecision) : '';

      dispatch(setConversionFromAmount(value));
      dispatch(setConversionFromAmountErr(''));

      if (outOfMinLimit) {
        dispatch(setConversionFromAmountErr(
          t('messages.min_exchange_warning', {
            amount: conversionFromCoin.minAmount,
          }),
        ));
      } else if (outOfMaxLimit) {
        dispatch(setConversionFromAmountErr(
          t('messages.max_exchange_warning', {
            amount: conversionFromCoin.maxAmount,
          }),
        ));
      } else if (insufficientFundsWarning) {
        const currentAmount = toFixedAndTrim(value, conversionFromCoin.decimalPrecision);
        const formattedBalance = toFixedAndTrim(userCoinBalance, conversionFromCoin.decimalPrecision);
        
        dispatch(setConversionFromAmountErr(
          t(
            'messages.available_exchange_warning', 
            {
              current_amount: `${formattedBalance} ${conversionFromCoin.symbol}`, 
            },
          ),
        ));
      }
    } else {
      dispatch(setConversionFromAmount(value));
      dispatch(setConversionFromAmountErr('Invalid value'));
    }

    if (conversionToCoin) {
      const coinSymbol = conversionToCoin?.symbol;
      const exchangeCurrencyRate = exchangeRates[coinSymbol] ?? 0;

      const conversionFromFeeRate = conversionFromCoin.conversionFee || 0;

      const conversionFromFee = calculateFee(conversionFromFeeRate, value, conversionFromCoin.decimalPrecision);
      const conversionFromAmountWithoutFee = toFixedAndTrim((value - conversionFromFee), conversionFromCoin.decimalPrecision);

      const conversionToAmount = toFixedAndTrim(
        (conversionFromAmountWithoutFee * exchangeCurrencyRate),
        conversionToCoin.decimalPrecision,
      );

      dispatch(setExchangeRate(exchangeCurrencyRate));
      dispatch(setConversionToAmount(conversionToAmount));
    } else {
      dispatch(setConversionToAmountErr('No found currency data'));
    }
  };

  const handleSubmit = async () => {
    if (parseFloat(conversionFromAmount) < conversionFromCoin.minAmount || userCoinBalance <= 0) {
      Swal.fire({
        text: t('messages.exchange_error'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const fromConversionFee = conversionFromCoin.conversionFee;
      const fromDecimal = conversionFromCoin.decimalPrecision;
      const finalConvertedFee = fromConversionFee > 0 ? calculateFee(fromConversionFee, conversionFromAmount, fromDecimal) : 0;
      const finalFromAmountWithoutFee = parseFloat(conversionFromAmount) - Number(finalConvertedFee);
      const finalFromTrimAmountWithoutFee = toFixedAndTrim(finalFromAmountWithoutFee, conversionFromCoin.decimalPrecision);

      const data = {
        fromCurrency: conversionFromCoin?._id,
        toCurrency: conversionToCoin?._id,
        fromCurrencySymbol: conversionFromCoin?.symbol,
        toCurrencySymbol: conversionToCoin?.symbol,
        fromAmountWithoutFee: Number(finalFromTrimAmountWithoutFee),
        fromAmount: Number(toFixedAndTrim(conversionFromAmount, conversionFromCoin.decimalPrecision)),
        conversionFee: Number(finalConvertedFee),
      };

      dispatch(addInternalOrder(data, userId));
    }
  };

  useEffect(() => {
    if (!isCreatingExchangeOrder) handleCloseConfirmation();
  }, [isCreatingExchangeOrder]);

  // update accounts after exchange effect
  useEffect(async () => {
    if (success) {
      await Swal.fire({
        text: t('messages.order_added_success'),
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });

      dispatch(setConversionFromAmount(0));
      dispatch(setConversionToAmount(0));
    }
  }, [success]);

  // update balance after exchange effect
  useEffect(() => {
    if (success) {
      const specifiedAmountData = amounts.find((row) => row.currencyId === conversionFromCoin._id);
      const selectedAmount = specifiedAmountData?.amount ?? 0;
      const moneyAvailable = nonNegativeAmount(selectedAmount);

      dispatch(setUserCoinBalance(moneyAvailable));
      dispatch(clearData());
      dispatch(setIsDataReady(false));
    }
    if (amounts && amounts.length && conversionFromCoin) {
      dispatch(updateUserBalance(conversionFromCoin));
    }
  }, [amounts]);

  if (!isDataReady) {
    return <FullPageLoader />;
  }

  return (
    <section className="header-padding swap-header-padding">
      <div className="exchange-crypto swap-exchange-crypto">
        <div className="container">
          <div className="swap-crypto-fields">
              
            <h1 className="text-capitalize text-center">{t('labels.swap')}</h1>

            <ExchangeBlock handleUserInput={handleUserInput} />
            <ShowExchangeInfo />
            <AmountSelectionButtons handleUserInput={handleUserInput} />
            <ConfirmationButton showConfirmation={handleShowConfirmation} />

            {isExchangeRatesLoading ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} size="2xl" /> : null}

          </div>
        </div>
        <div className="batton connect-device batton-opacity" />
      </div>

      <ConfirmationModal
        showConfirmation={showConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        handleSubmit={handleSubmit}
      />
    </section>
  );
}

export default ExchangeStarbitrex;
