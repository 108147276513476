import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { createTheme } from 'react-data-table-component';
import Trans from '../assets/images/transactions-icon.svg';
import { getInternalOrders } from '../redux/internalOrder/internalOrderActions';
import { getDeposits, getWithdraws } from '../redux/externalTransactions/externalTransactionActions';
import { getFiatWithdraws } from '../redux/externalFiatTransactions/externalFiatTransactionActions';
import { getBankWithdraws } from '../redux/externalBankTransactions/externalBankTransactionActions';
import { getAdminDeposits, getAdminWithdraws } from '../redux/transactions/transactionActions';
import { getUserStakingInvestments } from '../redux/stakingInvestment/stakingInvestmentActions';
import { getCurrency } from '../redux/currencies/currencyActions';
import { transactionsDataTableSchema } from '../DataTableSchemas/transactionsDataTableSchema';
import { GetCoinImg } from '../helpers/getCoinImg';
import { getCurrencyRates } from '../redux/currencyRate/currencyRateActions';

function TransactionsStarbitrexPage() {
  createTheme(
    'solarizedd',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#0c0d14',
      },
      context: {
        background: '#0c0d14',
        text: '#FFFFFF',
      },
      divider: {
        default: '#fff',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user?.user?._id);
  const orders = useSelector((state) => state.internalOrders?.orders?.userOrders);
  const withdraws = useSelector((state) => state.externalTransactions?.withdraws?.withdraws);
  const deposits = useSelector((state) => state.externalTransactions?.deposits?.deposits);
  const adminDeposits = useSelector((state) => state.adminTransactions?.adminDeposits?.adminDeposits);
  const adminWithdraws = useSelector((state) => state.adminTransactions?.adminWithdraws?.adminWithdraws);
  const cardFiatWithdraws = useSelector((state) => state.externalFiatTransactions?.fiatWithdraws?.withdraws);
  const bankFiatWithdraws = useSelector((state) => state?.externalBankTransactions?.bankWithdraws?.withdraws);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const allStakingInvestments = useSelector(
    (state) => state?.stakingInvestment?.stakingInvestments,
  );
  const currencyRates = useSelector((state) => state.currencyRates);
  const [transactions, setTransactions] = useState([]);

  const [displayData, setDisplayData] = useState([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [currencyFilter, setCurrencyFilter] = useState('');
  const [displayMessage, setDisplayMessage] = useState(false);

  useEffect(async () => {
    dispatch(getCurrency());
    if (userId) {
      Promise.allSettled([
        dispatch(getInternalOrders(userId)),
        dispatch(getDeposits(userId)),
        dispatch(getWithdraws(userId)),
        dispatch(getAdminDeposits(userId)),
        dispatch(getAdminWithdraws(userId)),
        dispatch(getFiatWithdraws(userId)),
        dispatch(getBankWithdraws(userId)),
        dispatch(getUserStakingInvestments(userId)),
      ]);
    }
  }, [userId]);

  useEffect(() => {
    dispatch(getCurrencyRates('USD'));
  }, []);

  useEffect(() => {
    if (orders && withdraws && deposits && adminDeposits && adminWithdraws && cardFiatWithdraws && bankFiatWithdraws && allStakingInvestments) {
      const combineData = [...orders, ...withdraws, ...deposits, ...adminDeposits, ...adminWithdraws, ...cardFiatWithdraws, ...bankFiatWithdraws, ...allStakingInvestments];
      setTransactions(combineData);
    }
  }, [orders, withdraws, deposits, adminDeposits, adminWithdraws, cardFiatWithdraws, bankFiatWithdraws, allStakingInvestments]);

  useEffect(() => {
    if (transactions && transactions.length) {
      const dataArr = [];

      transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((row) => {
        // Check for admin Transactions
        if (row.txHash) {
          if (!row.isResolved) return;
          const thisCoin = currencies.find((co) => co.symbol === row.currency);
          if (thisCoin) {
            if (row.transactionType === 0 || row.transactionType === false) { // Check if withdraw or deposit
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '+',
                type: 1,
                category: t('labels.deposit'),
                txHash: row.txHash,
                fromAddress: row.fromAddress,
                toAddress: row.toAddress,
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
                hash: row.hash,
              });
            } else {
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '-',
                type: 1,
                category: t('labels.withdrawal'),
                txHash: row.txHash,
                fromAddress: row.fromAddress,
                toAddress: row.toAddress,
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
              });
            }
          }
        } else if (row.userAccountId || row.toCard || row.toIban) {
          if (!row.isResolved) return;

          const thisCoin = currencies.find((co) => co.symbol === row.currency);
          if (thisCoin) {
            if (row.transactionType === 0 || row.transactionType === false) { // Check if withdraw or deposit
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '+',
                type: 1,
                category: t('labels.deposit'),
                txHash: row.userAccountId ? row.userAccountId : (row.toCard ? row.toCard : row.toIban),
                fromAddress: row.fromAddress ? row.fromAddress : '-',
                toAddress: row.toAddress ? row.toAddress : '-',
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
                hash: row.hash,
              });
            } else {
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '-',
                type: 1,
                category: t('labels.withdrawal'),
                txHash: row.userAccountId ? row.userAccountId : (row.toCard ? row.toCard : row.toIban),
                fromAddress: row.fromAddress ? row.fromAddress : '-',
                toAddress: row.toAddress ? row.toAddress : '-',
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
              });
            }
          }
        } else if (!row.toCurrency && !Object.hasOwnProperty.call(row, 'investedAmount')) {
          if (!row.isResolved) return;

          const thisCoin = currencies.find((co) => co.symbol === row.currency);
          if (thisCoin) {
            if (row.transactionType === 0 || row.transactionType === false) { // Check if withdraw or deposit
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '+',
                type: 1,
                category: t('labels.deposit'),
                txHash: row.userId,
                fromAddress: row.fromAddress,
                toAddress: row.toAddress,
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
                hash: row.hash,
              });
            } else if (row.transactionType === 1) {
              dataArr.push({
                symbol: row?.currency,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row?.amount,
                sign: '-',
                type: 1,
                category: t('labels.withdrawal'),
                txHash: row.userId,
                fromAddress: row.fromAddress,
                toAddress: row.toAddress,
                date: row.createdAt,
                info: row.additionalInfo,
                resolveStatus: row.isResolved,
              });
            }
          }
        } else if (Object.hasOwnProperty.call(row, 'investedAmount')) {
          const thisCoin = currencies.find((co) => co._id === row.currencyId);

          if (row.isReleased) {
            dataArr.push({
              symbol: thisCoin?.symbol,
              coin: thisCoin?.name,
              color: thisCoin?.color,
              amount: row.finalEarnings,
              txHash: row._id,
              sign: '+',
              type: 1,
              category: t('labels.staking_profit'),
              date: row.releaseTime,
            });
          }

          dataArr.push({
            symbol: thisCoin?.symbol,
            coin: thisCoin?.name,
            color: thisCoin?.color,
            amount: row.investedAmount,
            txHash: row._id,
            sign: '-',
            type: 1,
            category: t('labels.staking_investment'),
            date: row.createdAt,
          });
        } else {
          if (!row.isResolved) return;

          dataArr.push({
            symbol: row.toCurrency.symbol,
            coin: row.toCurrency.name,
            color: row.toCurrency?.color,
            amount: row.convertedAmount,
            sign: '+',
            type: 2,
            category: t('labels.conversion'),
            id: row._id,
            otherCoin: row.fromCurrency.name,
            otherAmount: row.fromAmount,
            rate: row.conversionRate,
            date: row.createdAt,
            info: row.additionalInfo,
            resolveStatus: row.isResolved,
          });
          dataArr.push({
            symbol: row.fromCurrency.symbol,
            coin: row.fromCurrency.name,
            color: row.fromCurrency?.color,
            amount: row.fromAmount,
            sign: '-',
            type: 2,
            category: t('labels.conversion'),
            id: row._id,
            otherCoin: row.toCurrency.name,
            otherAmount: row.convertedAmount,
            rate: row.conversionRate,
            date: row.createdAt,
            info: row.additionalInfo,
            resolveStatus: row.isResolved,
          });
        }
        return dataArr;
      });
      if (currencyFilter === '' && typeFilter === '') setDisplayData(dataArr);
      else {
        setDisplayData(currencyFilter !== ''
          ? (typeFilter !== '' ? dataArr.filter((c) => c.symbol === currencyFilter).filter((c) => c.category === typeFilter) : dataArr.filter((c) => c.symbol === currencyFilter))
          : typeFilter !== '' ? dataArr.filter((c) => c.category === typeFilter) : dataArr);
      }
    }
  }, [transactions, currencyFilter, typeFilter]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const copyTxtHash = async () => {
    setDisplayMessage(true);
    await delay(5000);
    setDisplayMessage(false);
  };

  const columns = transactionsDataTableSchema(GetCoinImg, copyTxtHash, displayMessage, currencyRates);

  return (
    <section className="header-padding">
      <div className="transactions-page padding50">
        <div className="container-fluid">
          <div className="d-flex align-items-center transactions-heading-div">
            <h1 className="text-white transactions-heading">{t('labels.transactions')}</h1>
            <img src={Trans} alt="" className="img-fluid transactions-icon" />
          </div>
          <div className="transactions-btns">
            <button type="button" className="btn">
              {t('labels.type')}
            </button>
            <button type="button" className="btn">
              {t('labels.pair')}
            </button>
            <button type="button" className="btn">
              {t('labels.note')}
            </button>
            <button type="button" className="btn">
              {t('labels.status')}
            </button>
            <button type="button" style={{ border: '0' }} className="btn">
              {t('labels.amount')}
            </button>
          </div>
          <div className="all-assets-types mb-4">
            <div className="dropdown all-assets-dd">
              <button
                className="btn text-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {currencyFilter || t('labels.all_assets')}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a className="dropdown-item" onClick={() => setCurrencyFilter('')}>All</a>
                </li>
                {currencies && currencies.map((currency) => (
                  <li key={currency._id}>
                    <a className="dropdown-item" onClick={() => setCurrencyFilter(currency.symbol)}>
                      <img src={GetCoinImg(currency.symbol)} alt="" className="img-fluid pe-1" height={25} width={25} />
                      {currency?.name}
                    </a>
                  </li>
                ))}

              </ul>
            </div>
            <div className="dropdown all-types-dd ms-2">
              <button
                className="btn text-white dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {typeFilter || t('labels.all_types')}
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter('')}>{t('labels.all')}</a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter(t('labels.deposit'))}>{t('labels.deposit')}</a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter(t('labels.withdrawal'))}>{t('labels.withdrawal')}</a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter(t('labels.conversion'))}>{t('labels.conversion')}</a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter(t('labels.staking_investment'))}>{t('labels.staking_investment')}</a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => setTypeFilter(t('labels.staking_profit'))}>{t('labels.staking_profit')}</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <DataTable
              columns={columns}
              data={displayData}
              pagination
              className="transactions-datatable"
              persistTableHead
              theme="solarizedd"
              noDataComponent={t('labels.no_records')}
              paginationComponentOptions={{
                rowsPerPageText: t('pagination.rows_per_page'),
                rangeSeparatorText: t('pagination.range_separator'),
              }}
            />
          </div>
        </div>
        <div className="batton connect-device batton-opacity" />
      </div>
    </section>
  );
}

export default TransactionsStarbitrexPage;
