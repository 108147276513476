import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/fr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  getStakings,
  updateStakingState,
  getUserStakings,
} from '../redux/staking/stakingActions';
import {
  addStakingInvestment,
  updateStakingInvestmentState,
} from '../redux/stakingInvestment/stakingInvestmentActions';
import { getAccount } from '../redux/account/accountActions';
import { GetCoinImg } from '../helpers/getCoinImg';
import OFFER from '../assets/images/special-offer.png';
import StakingList from '../components/SimpleEarn/StakingList';
import { nonNegativeAmount } from '../redux/apiHelper';

function Staking() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [, setLoader] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [filterCurrency, setFilterCurrency] = useState('');
  const [orderAmount, setOrderAmount] = useState('');
  const [orderAmountErr, setOrderAmountErr] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedPct, setSelectedPct] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [stakings, setStakings] = useState([]);

  const allStakings = useSelector((state) => state?.staking?.stakings);
  const stakingsFetched = useSelector((state) => state?.staking?.stakingsFetched);
  const userId = useSelector((state) => state.user?.user?._id);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const stakingInvestmentError = useSelector((state) => state.stakingInvestment?.stakingInvestmentError);
  const stakingInvestmentAdded = useSelector((state) => state.stakingInvestment?.stakingInvestmentAdded);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setOrderAmount('');
    setOrderAmountErr('');
    setMaxAmount(0);
    setSelectedRow({});
    setSelectedPct(null);
    setTermsAgreed(false);
    setShow(false);
    dispatch(getAccount(userId));
  };

  useEffect(() => {
    if (userId) {
      Promise.all([
        dispatch(getAccount(userId)),
        dispatch(getUserStakings(userId)),
      ]);
    } 
  }, [userId]);

  useEffect(() => {
    if (stakingsFetched) {
      dispatch(updateStakingState());
      setLoader(false);
    }
    if (allStakings?.length > 0) {
      setStakings(allStakings);
      setFilteredItems(allStakings);
    } else {
      setStakings([]);
      setFilteredItems([]);
    }
  }, [allStakings, stakingsFetched]);

  const searchByCurrency = (e) => {
    setFilterCurrency(e.target.value);
    const searchText = e.target.value;
    const searchData = stakings.filter(
      (row) => row?.currency?.name
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase())
        || row?.currency?.symbol?.toLowerCase()?.includes(searchText.toLowerCase()),
    );
    setFilteredItems(searchData);
  };



  const findAmount = (coinId) => parseFloat(nonNegativeAmount(amounts.find((row) => row.currencyId === coinId)?.amount));

  const handleSubscribeDetails = (currentStaking) => {
    setOrderAmount('');
    setOrderAmountErr('');
    setMaxAmount(findAmount(currentStaking?.currency?._id));
    setSelectedRow(currentStaking);
    handleShow();
  };

  const handleOrderAmountChange = (event, max = false) => {
    let value = 0;
    if (max) {
      value = maxAmount;
    } else {
      value = event.target.value;
    }

    setOrderAmount(value);

    if (value > maxAmount) {
      setOrderAmountErr(t('staking.not_enough_balance'));
    } else if (value < 0) {
      setOrderAmountErr(t('messages.invalid_number_warning'));
    } else if (value < selectedRow?.minDeposit) {
      setOrderAmountErr(t('staking.min_investment_msg', { investment: `${selectedRow?.minDeposit}` }));
    } else {
      setOrderAmountErr('');
    }
  };

  useEffect(() => {
    if (stakingInvestmentError) {
      setLoader(false);
      dispatch(updateStakingInvestmentState());
    }
  }, [stakingInvestmentError]);

  useEffect(() => {
    if (stakingInvestmentAdded) {
      setLoader(false);
      handleClose();
      dispatch(updateStakingInvestmentState());
    }
  }, [stakingInvestmentAdded]);

  const handleAddStakingInvestment = () => {
    if (!orderAmountErr && orderAmount && termsAgreed) {
      setLoader(true);
      const releaseDate = moment(Date.now())
        .add(Number(selectedPct.period), `${selectedPct.durationType}`)
        .toDate();

      const data = {
        currencyId: selectedRow?.currency?._id,
        userId,
        investedAmount: orderAmount ? Number(orderAmount) : 0,
        pct: selectedPct.percent,
        isFlexible: selectedPct.isFlexible,
        releaseTime: releaseDate,
        period: !selectedPct.isFlexible ? `${selectedPct.period} ${selectedPct.durationType}` : 'flexible',
      };

      dispatch(addStakingInvestment(data));
    } else {
      toast.success(
        t('staking.make_sure_msg'),
        {
          autoClose: 1000,
        },
      );
    }
  };

  return (
    <>
      <section className="simple-earn-top header-padding">
        <div className="container">
          <div className="content-box simple-earn-wrapper">
            <div className="row head-info-wrap align-items-center">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="earn-heading">
                  <h4 className="mb-1 text-capitalize">{t('staking.simple_earn')}</h4>
                  <p>{t('staking.way_text')}</p>
                </div>
              </div>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
              <div className="tbl-head-wrap d-flex flex-row">
                <div className="table-heading">
                  <h2>{t('staking.protected_products')}</h2>
                  <p>{t('staking.get_stable_text')}</p>
                </div>
                <div className="search-check-holder">
                  <div className="search-wrap">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder={t('labels.search')}
                        name="filterCurrency"
                        value={filterCurrency}
                        onChange={(e) => searchByCurrency(e)}
                      />
                      <FontAwesomeIcon icon={faSearch} className="search" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table>
                  <tr>
                    <th>{t('labels.coin')}</th>
                    <th>{t('labels.duration')}</th>
                    <th />
                  </tr>
                  {filteredItems?.length > 0 ? (
                    filteredItems.map((currentStaking) => (
                      <tr key={`staking-${currentStaking?._id}`}>
                        <td>
                          <div className="offer-img-wrap">
                            <img alt="" src={OFFER} className="img-fluid" />
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div className="icon-wrap">
                              <img
                                alt=""
                                src={GetCoinImg(
                                  currentStaking?.currency?.symbol,
                                )}
                                className="img-fluid"
                              />
                            </div>
                            <div className="icon-info">
                              <h1 className="text-uppercase">
                                {currentStaking?.currency?.symbol
                                  ? currentStaking?.currency?.symbol
                                  : 'N/A'}
                              </h1>
                              <p className="text-capitalize mb-0">
                                {currentStaking?.currency?.name
                                  ? currentStaking?.currency?.name
                                  : 'N/A'}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="duration-wrapper d-flex flex-row align-items-center">
                            {
                              currentStaking.options?.map(((option) => (
                                <div className="form-check" key={`${currentStaking._id}-${option._id}`}>
                                  {`${option.isFlexible ? t('staking.flexible') : `${option.period} ${t(`staking.${option.durationType}`)}`}`}
                                </div>
                              )))
                            }
                          </div>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn-complete"
                            onClick={() => {
                              handleSubscribeDetails(currentStaking);
                            }}
                          >
                            {t('labels.subscribe')}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td />
                      <td />
                      <td>{t('messages.no_record_found')}</td>
                      <td />
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
          <StakingList />
        </div>
      </section>

      <Modal show={show} onHide={handleClose} className="subscribe-modal">
        <Modal.Header closeButton />
        <div className="subscription-popup">
          <div className="popup-header d-flex flex-row align-items-center">
            <h1 className="text-capitalize">{t('labels.subscribe')}</h1>
            <div className="img-outer-holder d-flex flex-row align-items-center">
              <div className="img-holder d-flex align-items-center">
                <img
                  alt=""
                  src={GetCoinImg(selectedRow?.currency?.symbol)}
                  className="img-fluid"
                />
                <h2 className="text-uppercase">
                  {selectedRow?.currency?.symbol
                    ? selectedRow?.currency?.symbol
                    : 'N/A'}
                </h2>
              </div>
            </div>
          </div>
          <div className="popup-body">
            <h2 className="text-capitalize">{t('labels.choose_duration')}</h2>
            <div className="radio-btn-wrapper">
              {
                selectedRow.options?.map((option) => (
                  <div className="form-check" key={`dur-${selectedRow._id}-${option._id}`}>
                    <input
                      type="radio"
                      name={`${selectedRow._id}-${option._id}`}
                      id={`${selectedRow._id}-${option._id}`}
                      value={option._id}
                      checked={selectedPct?._id === option._id}
                      onChange={() => setSelectedPct(option)}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`${selectedRow._id}-${option._id}`}
                    >
                      {option.isFlexible ? t('staking.flexible') : `${t('staking.locked')} ${option.period} ${t(`staking.${option.durationType}`)}`}
                    </label>
                  </div>
                ))
              }
            </div>
            <div className="subscription-amount-holder">
              <h2 className="text-capitalize">{t('labels.subscription_amount')}</h2>
              <div className="input-holder">
                <input
                  type="number"
                  name="orderAmount"
                  value={orderAmount}
                  onChange={handleOrderAmountChange}
                  className="form-control"
                  placeholder={t('labels.enter_amount')}
                />
                <div className="img-holder d-flex align-items-center">
                  <span
                    onClick={(event) => handleOrderAmountChange(event, true)}
                    style={{ cursor: 'pointer' }}
                  >
                    {t('labels.max')}
                  </span>
                  <div className="img-txt-holder d-flex align-items-center">
                    <img
                      alt=""
                      src={GetCoinImg(selectedRow?.currency?.symbol)}
                      className="img-fluid"
                    />
                    <h2 className="text-uppercase">
                      {selectedRow?.currency?.symbol
                        ? selectedRow?.currency?.symbol
                        : 'N/A'}
                    </h2>
                  </div>
                </div>
              </div>
              <div>
                {orderAmountErr ? (
                  <span className="errMsg">{orderAmountErr}</span>
                ) : (
                  ''
                )}
              </div>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                borderBottom: '1px solid #424242',
                paddingBottom: '10px',
              }}
              >
                <h3
                  className="text-capitalize"
                >
                  {`${t('labels.min')}:`}
                  {' '}
                  {selectedRow?.minDeposit}
                  {' '}
                  {selectedRow?.currency?.symbol
                    ? selectedRow?.currency?.symbol
                    : 'N/A'}
                </h3>
                <h3 className="text-capitalize">
                  {maxAmount}
                  {' '}
                  {selectedRow?.currency?.symbol
                    ? selectedRow?.currency?.symbol
                    : 'N/A'}
                  {' '}
                  {t('labels.available')}
                </h3>
              </div>

            </div>
            <div
              className="crypto-have crypto-info-text"
              style={{ flexDirection: 'column', backgroundColor: 'unset' }}
            >
              <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
                <p>{t('labels.subscription_date')}</p>
                <p>{moment(Date.now()).format('LLL')}</p>
              </div>
              {
                !!(selectedPct && !selectedPct.isFlexible) && (
                  <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
                    <p>{t('labels.release_date')}</p>
                      {' '}
                    <p>
                      {moment(Date.now())
                        .add(
                          Number(selectedPct.period),
                          `${selectedPct.durationType}`,
                        )
                        .format('LLL')}
                    </p>
                  </div>
                )
              }
            </div>
            <div>
              <p style={{ fontSize: '14px', color: '#fff' }}>{`${t('labels.warning')}!`}</p>
               
              <div className="bottom-input-wrapper">
                <p className="text-white">
                  {t('staking.profit_sbj_text')}
                </p>
                <p className="text-white" style={{ margin: 0 }}>
                  {t('staking.profit_not_predicted_text')}
                </p>
              </div>
            </div>
            {
              !!(selectedPct && !selectedPct.isFlexible && orderAmount) && (
                <div className="estimated-text d-flex flex-column align-items-center">
                  <h3>{t('labels.final_profit')}</h3>
                  <h2 className="text-green">{`${Number(((selectedPct.percent / 100) * orderAmount)) + Number(orderAmount)} ${selectedRow.currency.symbol}`}</h2>
                </div>
              )
            }

            <div className="btn-wrapper d-flex flex-column">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={termsAgreed}
                  checked={termsAgreed}
                  onChange={() => setTermsAgreed(!termsAgreed)}
                />
                <label
                  className="form-check-label"
                  htmlFor="termsandconditions"
                >
                  {t('messages.terms_and_cons_agreement')}
                </label>
              </div>
              {!orderAmountErr && orderAmount && termsAgreed ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="text-capitalize btn enter-btn2"
                    onClick={handleAddStakingInvestment}
                  >
                    {t('labels.confirm')}
                  </button>
                </div>
              ) : (
                <dl />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Staking;
