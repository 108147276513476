import React from 'react';
import styles from './PercentProgressComponents.module.css';
import { PercentProgressBar } from './PercentProgressBar';

const getTextColor = (percent) => {
  if (percent > 0) return styles.success;
  if (percent < 0) return styles.danger;
  return 'neutral';
};

export function PercentProgressCard({ progress }) {
  const mapItemToPercents = (item) => (item?.prevValue ? (Number(item.value) / Number(item.prevValue || 1)) * 100 - 100 : undefined);
  const mappedBalances = progress.map((item) => ({
    ...item,
    percent: item?.percentage ?? mapItemToPercents(item),
  }));

  return (
    <div className={styles['ios-card']}>
      <div className={styles['ios-props']}>
        {mappedBalances.map((item) => (
          <>
            {item?.percent !== undefined && (
            <PercentProgressBar currentPercent={item.percent} />
            )}
            <div className={styles['ios-card-section']} key={item.name}>
              <p className={styles['ios-title']}>{item.name}</p>
              <p className={`${styles['ios-balance']} ${getTextColor(item.percent)}`}>
                {item?.symbol ?? '$'}
                {item.value.toFixed(item?.toFixed ?? 2)}
              </p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
