import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PercentProgressComponents.module.css';

export function PercentProgressBar({ currentPercent }) {
  const { t } = useTranslation();
    
  const progressValue = Number(currentPercent);
  const isNegative = progressValue < 0;

  return (
    <div className={styles['progress-container']}>
      <div className={`${styles['ios-comparison']} ${isNegative ? styles.danger : styles.success}`}>
        <span className={styles['ios-arrow']}>{isNegative ? '⬇' : '⬆'}</span>
        <span className={styles['ios-comparison-text']}>
          {`${progressValue.toFixed(2)} % ${t('portfolio.vs_previous_month')}`}
        </span>
      </div>

      <div className={styles['progress-bar']}>
        <div className={`${styles['progress-point']} ${styles['progress-point-left']}`} />
        <div className={`${styles['progress-point']} ${styles['progress-point-center']}`} />
        <div className={`${styles['progress-point']} ${styles['progress-point-right']}`} />
        <div
          className={`${styles['progress-fill']} ${isNegative ? styles.negative : styles.positive}`}
          style={{
            width: `${Math.min(Math.abs(progressValue) / 2, 50)}%`,
            left: isNegative ? `${50 - Math.min(Math.abs(progressValue) / 2, 50)}%` : '50%',
          }}
        />
      </div>

      <div className={styles['progress-labels']}>
        <span>
          {progressValue < -100 ? progressValue.toFixed(0) : -100}
          %
        </span>
        <span>0%</span>
        <span>
          {progressValue > 100 ? progressValue.toFixed(0) : 100}
          %
        </span>
      </div>
    </div>
  );
}
