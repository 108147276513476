import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import DataTable, { createTheme } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getCurrency } from '../redux/currencies/currencyActions';
import { getAccount } from '../redux/account/accountActions';
import { getInternalOrders } from '../redux/internalOrder/internalOrderActions';
import {
  getDeposits,
  getWithdraws,
} from '../redux/externalTransactions/externalTransactionActions';
import { getAdminDeposits, getAdminWithdraws } from '../redux/transactions/transactionActions';
import { getUserStakingInvestments } from '../redux/stakingInvestment/stakingInvestmentActions';
import { getFiatWithdraws } from '../redux/externalFiatTransactions/externalFiatTransactionActions';
import { getBankWithdraws } from '../redux/externalBankTransactions/externalBankTransactionActions';
import { GetCoinImg } from '../helpers/getCoinImg';
import FullPageLoader from '../components/FullPageLoader/fullPageLoader';
import Buttons from '../components/FiatCoinAvtivityStarbitrex/ActivityButtons';
import { CoinActivityDataTableSchema } from '../DataTableSchemas/CoinActivityDataTableSchema';

import './CoinAvtivityStarbitrexPage.css';
import { nonNegativeAmount } from '../redux/apiHelper';

const getFormattedAmount = (amounts, selectedCurrency) => {
  const selectedAmount = amounts?.find((c) => c.currencyId === selectedCurrency._id);

  const balance = nonNegativeAmount((selectedAmount?.amount ?? 0) + (selectedAmount?.credit_amount ?? 0));
  const creditAmount = -(selectedAmount?.credit_amount ?? 0);
  const availableCreditAmount = (selectedAmount?.amount ?? 0) - balance;

  return { amount: balance.toFixed(4), creditAmount: creditAmount.toFixed(4), availableCreditAmount: availableCreditAmount.toFixed(4) };
};

function FiatCoinActicityStarbitrex() {
  createTheme(
    'solarizedd',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#0c0d14',
      },
      context: {
        background: '#0c0d14',
        text: '#FFFFFF',
      },
      divider: {
        default: '#fff',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );

  const { t } = useTranslation();
  const { coin } = useParams();
  const dispatch = useDispatch();
  const currencyDataState = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const userId = useSelector((state) => state.user?.user?._id);
  const orders = useSelector((state) => state.internalOrders?.orders?.userOrders);
  const withdraws = useSelector((state) => state.externalTransactions?.withdraws?.withdraws);
  const deposits = useSelector((state) => state.externalTransactions?.deposits?.deposits);
  const adminDeposits = useSelector(
    (state) => state.adminTransactions?.adminDeposits?.adminDeposits,
  );
  const adminWithdraws = useSelector(
    (state) => state.adminTransactions?.adminWithdraws?.adminWithdraws,
  );
  const cardFiatWithdraws = useSelector(
    (state) => state.externalFiatTransactions?.fiatWithdraws?.withdraws,
  );
  const bankFiatWithdraws = useSelector(
    (state) => state?.externalBankTransactions?.bankWithdraws?.withdraws,
  );
  const allStakingInvestments = useSelector(
    (state) => state?.stakingInvestment?.stakingInvestments,
  );
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [displayMessage, setDisplayMessage] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [currencyFilter, setCurrencyFilter] = useState('');
  const [coinIndex, setCoinIndex] = useState(0);
  const [currencyData, setCurrencyData] = useState([]);
  const { amount, availableCreditAmount, creditAmount } = getFormattedAmount(amounts, selectedCurrency);

  useEffect(() => {
    setLoader(true);
    dispatch(getCurrency());
    if (userId) {
      Promise.allSettled([
        dispatch(getInternalOrders(userId)),
        dispatch(getDeposits(userId)),
        dispatch(getWithdraws(userId)),
        dispatch(getAdminDeposits(userId)),
        dispatch(getAdminWithdraws(userId)),
        dispatch(getFiatWithdraws(userId)),
        dispatch(getBankWithdraws(userId)),
        dispatch(getUserStakingInvestments(userId)),
      ]);
    }
  }, [userId]);

  useEffect(() => {
    if (
      orders
      && withdraws
      && deposits
      && adminDeposits
      && adminWithdraws
      && cardFiatWithdraws
      && bankFiatWithdraws
      && allStakingInvestments
    ) {
      const combineData = [
        ...orders,
        ...withdraws,
        ...deposits,
        ...adminDeposits,
        ...adminWithdraws,
        ...cardFiatWithdraws,
        ...bankFiatWithdraws,
        ...allStakingInvestments,
      ];
      setTransactions(combineData);
    }
  }, [
    orders,
    withdraws,
    deposits,
    adminDeposits,
    adminWithdraws,
    cardFiatWithdraws,
    bankFiatWithdraws,
    allStakingInvestments,
  ]);

  useEffect(() => {
    dispatch(getCurrency());
    if (userId) dispatch(getAccount(userId));
  }, [userId]);

  useEffect(() => {
    if (currencyDataState && currencyDataState.length) {
      const currencyDataFilter = currencyDataState.filter((row) => row?.isFiat === true);
      const selectNewCurrency = currencyDataFilter?.find((row) => row.symbol === coin);
      if (selectNewCurrency) {
        setCurrencyData(currencyDataFilter);
        setSelectedCurrency(selectNewCurrency);
        setCoinIndex(currencyDataFilter?.findIndex((row) => row.symbol === coin));
      }
      setLoader(false);
    }
  }, [currencyDataState]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const copyTxtHash = async () => {
    setDisplayMessage(true);
    await delay(3000);
    setDisplayMessage(false);
  };

  useEffect(() => {
    if (transactions && transactions.length) {
      const dataArr = [];
      transactions
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((row) => {
          // Check for admin Transactions
          if (row.txHash) {
            if (!row.isResolved) return;

            const thisCoin = currencyData?.find((co) => co.symbol === row.currency);
            if (thisCoin) {
              if (!row.transactionType) {
                // Check if withdraw or deposit
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: row?.amount,
                  sign: '+',
                  type: 1,
                  category: `${t('labels.deposit')}`,
                  txHash: row.txHash,
                  fromAddress: row.fromAddress,
                  toAddress: row.toAddress,
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              } else {
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: row?.amount,
                  sign: '-',
                  type: 1,
                  category: `${t('labels.withdrawal')}`,
                  txHash: row.txHash,
                  fromAddress: row.fromAddress,
                  toAddress: row.toAddress,
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              }
            }
          } else if (row.userAccountId || row.toCard || row.toIban) {
            if (!row.isResolved) return;

            const thisCoin = currencyData.find((co) => co.symbol === row.currency);
            if (thisCoin) {
              if (!row.transactionType && row?.amount >= 0) {
                // Check if withdraw or deposit
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: row?.amount,
                  sign: '+',
                  type: 1,
                  category: `${t('labels.deposit')}`,
                  txHash: row.userAccountId
                    ? row.userAccountId
                    : row.toCard
                      ? row.toCard
                      : row.toIban,
                  fromAddress: row.fromAddress ? row.fromAddress : '-',
                  toAddress: row.toAddress ? row.toAddress : '-',
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              } else {
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: Math.abs(row?.amount),
                  sign: '-',
                  type: 1,
                  category: `${t('labels.withdrawal')}`,
                  txHash: row.userAccountId
                    ? row.userAccountId
                    : row.toCard
                      ? row.toCard
                      : row.toIban,
                  fromAddress: row.fromAddress ? row.fromAddress : '-',
                  toAddress: row.toAddress ? row.toAddress : '-',
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              }
            }
          } else if (!row.toCurrency && !Object.hasOwnProperty.call(row, 'investedAmount')) {
            if (!row.isResolved) return;

            const thisCoin = currencyData.find((co) => co.symbol === row.currency);
            if (thisCoin) {
              if (!row.transactionType) {
                // Check if withdraw or deposit
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: row?.amount,
                  sign: '+',
                  type: 1,
                  category: `${t('labels.deposit')}`,
                  txHash: row.userId,
                  fromAddress: row.fromAddress,
                  toAddress: row.toAddress,
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              } else {
                dataArr.push({
                  symbol: row?.currency,
                  coin: thisCoin?.name,
                  color: thisCoin?.color,
                  amount: row?.amount,
                  sign: '-',
                  type: 1,
                  category: `${t('labels.withdrawal')}`,
                  txHash: row.userId,
                  fromAddress: row.fromAddress,
                  toAddress: row.toAddress,
                  date: row.createdAt,
                  info: row.additionalInfo,
                  resolveStatus: row.isResolved,
                });
              }
            }
          } else if (Object.hasOwnProperty.call(row, 'investedAmount')) {
            const thisCoin = currencyData.find((co) => co._id === row.currencyId);

            if (!thisCoin) return;

            if (row.isReleased) {
              dataArr.push({
                symbol: thisCoin?.symbol,
                coin: thisCoin?.name,
                color: thisCoin?.color,
                amount: row.finalEarnings,
                txHash: row._id,
                sign: '+',
                type: 1,
                category: `${t('labels.staking_profit')}`,
                date: row.releaseTime,
              });
            }

            dataArr.push({
              symbol: thisCoin?.symbol,
              coin: thisCoin?.name,
              color: thisCoin?.color,
              amount: row.investedAmount,
              txHash: row._id,
              sign: '-',
              type: 1,
              category: `${t('labels.staking_investment')}`,
              date: row.createdAt,
            });
          } else {
            if (!row.isResolved) return;

            dataArr.push({
              symbol: row.toCurrency.symbol,
              coin: row.toCurrency.name,
              color: row.toCurrency?.color,
              amount: row.convertedAmount,
              sign: '+',
              type: 2,
              category: `${t('labels.conversion')}`,
              id: row._id,
              otherCoin: row.fromCurrency.name,
              otherAmount: row.fromAmount,
              rate: row.conversionRate,
              date: row.createdAt,
              info: row.additionalInfo,
              resolveStatus: row.isResolved,
            });
            dataArr.push({
              symbol: row.fromCurrency.symbol,
              coin: row.fromCurrency.name,
              color: row.fromCurrency?.color,
              amount: row.fromAmount,
              sign: '-',
              type: 2,
              category: `${t('labels.conversion')}`,
              id: row._id,
              otherCoin: row.toCurrency.name,
              otherAmount: row.convertedAmount,
              rate: row.conversionRate,
              date: row.createdAt,
              info: row.additionalInfo,
              resolveStatus: row.isResolved,
            });
          }
          return dataArr;
        });

      setDisplayData(dataArr.filter((c) => c.symbol === selectedCurrency?.symbol));
    }
  }, [transactions, selectedCurrency]);

  const changeCurrency = (currency) => {
    setSelectedCurrency(currency);
    setCoinIndex(currencyData?.findIndex((row) => row.symbol === currency?.symbol));
    window.history.pushState('', '', `/fiat-activity/${currency.symbol}`);
  };

  const settings = {
    initialSlide: coinIndex,
    focusOnSelect: true,
    dots: false,
    infinite: true,
    loop: false,
    speed: 500,
    slidesToShow: currencyData?.length > 10 ? 10 : currencyData?.length,
    slidesToScroll: 1,
    className: 'center',
    cursor: 'pointer',
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          beforeChange(prev, next) {
            changeCurrency(currencyData[next]);
          },
        },
      },
    ],
  };

  return (
    <dl>
      {loader ? (
        <FullPageLoader />
      ) : (
        <section className="header-padding">
          <div className="send-receive-crypto padding50">
            <div className="container-fluid send-receive-container">
              <div className="slider-search-container mb-3">
                <div className="activity-cion-search-box">
                  <FontAwesomeIcon
                    style={showSearch ? { color: 'green' } : { color: 'white' }}
                    icon={faSearch}
                    className="search"
                    onClick={() => {
                      if (showSearch) {
                        setCurrencyFilter('');
                      }
                      setShowSearch(!showSearch);
                    }}
                  />
                  {showSearch ? (
                    <div className="searchbox-input-fleid">
                      <input
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setCurrencyFilter(e.target.value)}
                      />
                    </div>
                  ) : null}
                </div>
                {selectedCurrency?.symbol ? (
                  <Slider {...settings}>
                    {currencyData
                          && currencyData.length > 0
                          && currencyData
                            ?.filter(
                              (row) => row.name.toLowerCase().includes(currencyFilter.toLowerCase())
                                      || row.symbol.toLowerCase().includes(currencyFilter.toLowerCase()),
                            )
                            .map((currency, index) => (
                              <div
                                className={
                                        selectedCurrency?.symbol === currency.symbol
                                          ? 'icon-symbol slider-current-grey'
                                          : 'icon-symbol'
                                      }
                                      // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                onClick={() => changeCurrency(currency)}
                              >
                                <h6 className="text-light">
                                  {' '}
                                  {currency?.name}
                                </h6>
                                <p style={{ color: 'gray' }}>{currency?.symbol}</p>
                              </div>
                            ))}
                  </Slider>
                ) : null}
              </div>
              <div className="crypto-container text-center p-0 single-column">
                <div
                  className="currency-balance-card text-white d-flex flex-row"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'left',
                  }}
                >
                  <img src={GetCoinImg(selectedCurrency.symbol)} alt="" className="img-fluid m-0" />
                  <div className="d-flex flex-column p-3">
                    <p className="currency-balance-card-label">
                      {`${t('labels.balance')}: ${amount} ${selectedCurrency.symbol}`}
                    </p>
                    <p className="currency-balance-card-label secondary">
                      {`${t('labels.credits')}: ${availableCreditAmount} / ${creditAmount} ${selectedCurrency.symbol}`}
                    </p>
                  </div>
                </div>
                <Buttons selectedCurrency={selectedCurrency} getCoinImg={GetCoinImg} />
              </div>

              <h3 className="text-center" style={{ color: selectedCurrency?.color }}>
                {t('labels.activity')}
              </h3>
              <div>
                <DataTable
                  columns={CoinActivityDataTableSchema(
                    GetCoinImg,
                    selectedCurrency,
                    copyTxtHash,
                    displayMessage,
                    t,
                  )}
                  data={displayData}
                  pagination
                  className="transactions-datatable"
                  persistTableHead
                  theme="solarizedd"
                  noDataComponent={t('labels.no_records')}
                  paginationComponentOptions={{
                    rowsPerPageText: t('pagination.rows_per_page'),
                    rangeSeparatorText: t('pagination.range_separator'),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </dl>
  );
}

export default FiatCoinActicityStarbitrex;
