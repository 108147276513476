/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import {
  GET_ORDER,
  ADD_ORDER,
  CLEAR_ORDER,
  GET_USER_ORDER,
  ORDER_ERROR,
  STOP_ORDER,
  START_ORDER,
  ORDER_PNL,
  UPDATE_SOCKET_ORDER,
} from './leverageOrderTypes';
import { apiHelper } from '../apiHelper';

export const getPnL = (pnl) => async (dispatch) => {
  try {
    dispatch({
      type: ORDER_PNL,
      payload: pnl,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getLeverageOrders = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/leverageOrder/', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUserLeverageOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/leverageOrder/${id}`, '');
    if (res?.data?.userOrders) {
      dispatch({
        type: GET_USER_ORDER,
        payload: res.data.userOrders,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addLeverageOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/leverageOrder/add', data);
    if (res?.data) {
      toast.success(res.data.message);
      dispatch({
        type: ADD_ORDER,
        payload: res.data,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(getUserLeverageOrders(data.userId));
  }
};

export const updateLeverageOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/leverageOrder/update', data);
    if (res?.data) {
      toast.success(res.data.message, {
        autoClose: 1000,
      });
      const { data } = res;
      // console.log(data);
      dispatch({
        type: ADD_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(getUserLeverageOrders(data.userId));
  }
};

export const marginNotification = (leverageData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/leverageOrder/margin-notifications', leverageData);
    if (res?.data) {
      toast.success(res.data.message);
      const { data } = res;
      // console.log(data);
      dispatch(getUserLeverageOrders(leverageData.userId));
    } else {
      dispatch({
        type: ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearLeverageOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ORDER,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const stopLeverageOrder = (id, rate, auto, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/leverageOrder/stop/${id}`, { stopRate: rate, autoStop: auto });
    if (res?.data?.success) {
      toast.success(res.data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: STOP_ORDER,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(getUserLeverageOrders(userId));
  }
};

export const startLeverageOrder = ({ id, startRate, userId }) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/leverageOrder/start/${id}`, { startRate });
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message, {
        autoClose: 1000,
      });
      dispatch({
        type: START_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } finally {
    dispatch(getUserLeverageOrders(userId));
  }
};

export const updateSocketOrder = (order) => (dispatch) => {
  if (order.prevExists) {
    dispatch({ type: START_ORDER });
  } else {
    dispatch({ type: UPDATE_SOCKET_ORDER, payload: order });
  }
};
