import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faLinkedin, faInstagram, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/images/ChainPilot.png';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-bg">
        <Container>
          <div className="footer-header">
            {/* <div className="footer-circle"></div> */}
            <div className="row">
              <div className="col-lg-4 mb-lg-0 mb-5">
                <a className="footer-mb d-block">
                  <figure className="mb-0">
                    <Image src={Logo} alt="" fluid />
                  </figure>
                </a>
                <p className="site-text">
                  {t('footer.main_text', { brand_name: process.env.REACT_APP_BRANDNAME })}
                </p>
                <div className="read-button">
                  <a className="read-btn" href="https://chainpilot.co.uk/about-us/">
                    {t('labels.read_more')}
                    <FontAwesomeIcon className="fa" icon={faAngleRight} />
                  </a>
                </div>
                {/* <h3 className="text-capitalize footer-mb">community</h3>
                <div className="social-icons">
                  <ul>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
                {' '}
                */}
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div className="row">
                  <div className="col-lg-4 col-md-4 mb-lg-0 mb-4">
                    <h3 className="text-capitalize footer-mb">{t('labels.about_us')}</h3>
                    <ul className="footer-links">
                      <li>
                        <a href="https://chainpilot.co.uk/about-us/">{t('labels.about')}</a>
                      </li>
                      <li>
                        <a href="https://chainpilot.co.uk/privacy-policy/">{t('labels.compliance')}</a>
                      </li>
                      <li>
                        <a href="https://chainpilot.co.uk/crypto-trading/">{t('labels.markets')}</a>
                      </li>
                      {/* <li>
                        <a>careers</a>
                      </li>
                      <li>
                        <a>{t('labels.business_contact')}</a>
                      </li>
                      <li>
                        <a>{t('labels.community')}</a>
                      </li>
                      <li>
                        <a>ChainPilot blog</a>
                      </li>
                      <li>
                        <a>{t('labels.terms')}</a>
                      </li>
                      <li>
                        <a>{t('labels.privacy')}</a>
                      </li>
                      <li>
                        <a>announcements</a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-lg-0 mb-4">
                    <h3 className="text-capitalize footer-mb">{t('labels.services')}</h3>
                    <ul className="footer-links">
                      <li>
                        <a href="https://cex.chainpilot.co.uk/deposit/USDT">{t('labels.buy_crypto')}</a>
                      </li>
                      <li>
                        <a href="https://chainpilot.co.uk/fund-your-account/">{t('labels.funding')}</a>
                      </li>
                      <li>
                        <a href="https://chainpilot.co.uk/account-type/">{t('labels.account_types')}</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 mb-lg-0 mb-1">
                    <h3 className="text-capitalize footer-mb">support</h3>
                    <ul className="footer-links">
                      <li>
                        <a>{t('labels.give_us_feedback')}</a>
                      </li>
                      <li>
                        <a>{t('labels.support_center')}</a>
                      </li>
                      <li>
                        <a>{t('labels.fees')}</a>
                      </li>
                      <li>
                        <a>{t('labels.trading_rules')}</a>
                      </li>
                      <li>
                        <a>ChainPilot blog</a>
                      </li>
                      <li>
                        <a>ChainPilot verify</a>
                      </li>
                    </ul>
                  </div>
                  {' '}
                  */}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="container-fluid custom-box">
          <div className="footer-bottom">
            <p className="copyright text-center mb-0">
              Copyright©ChainPilot 2023. All Right Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
