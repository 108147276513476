import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { widget as Widget } from '@wisecryptoanalysis/starbitrex-charts';
import { useTranslation } from 'react-i18next';
import FUTURESDATAFEED from './datafeeds/futuresDatafeed';
import SPOTDATAFEED from './datafeeds/spotDatafeed';
import { pairHelper } from '../../helpers/pairHelper';

export function TVChartContainer({ pair, market }) {
  const chartContainerRef = useRef();
  const [resolvedPair, setResolvedPair] = useState(null);
  const { i18n } = useTranslation();

  const getMarket = (market) => {
    if (market === 'spot') return SPOTDATAFEED;
    if (market === 'futures') return FUTURESDATAFEED;
    throw new Error('Market not selected');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const resolved = await pairHelper(pair);
        setResolvedPair(resolved);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [pair]);

  const defaultProps = {
    libraryPath: '/lib/chart/',
    symbol: `${process.env.REACT_APP_BRANDNAME}:${resolvedPair}`,
    interval: 5,
    fullscreen: true,
    datafeed: getMarket(market),
    chartsStorageUrl: 'https://saveload.tradingview.com',
    container: 'TVChartContainer',
    chartsStorageApiVersion: '0.01',
    userId: 'userId',
    clientId: `${process.env.REACT_APP_BRANDNAME}`,
  };

  useEffect(() => {
    const widgetOptions = {
      symbol: defaultProps.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: defaultProps.datafeed,
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale: i18n.language ?? 'en',
      disabled_features: ['use_localstorage_for_settings', 'header_symbol_search', 'symbol_search_hot_key', 'header_saveload', 'header_compare'],
      enabled_features: ['control_bar', 'timeframes_toolbar', 'header_in_fullscreen_mode'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      studies_overrides: defaultProps.studiesOverrides,
      widgetType: 'widget',
      theme: 'Dark',
      show_popup_button: false,
      autosize: true,
    };

    const tvWidget = new Widget(widgetOptions);

    return () => {
      tvWidget.remove();
    };
  }, [i18n.language, resolvedPair]);

  return (
    <div
      ref={chartContainerRef}
      className="TVChartContainer"
    />
  );
}
