import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  selectCreditCardBackData, selectCreditCardBackFile, selectCreditCardFrontData, selectCreditCardFrontFile, selectIsDataLoading, 
} from '../../../redux/kycVerification/kycVerificationSelectors';
import {
  setCreditCardBackData, setCreditCardBackFile, setCreditCardFrontData, setCreditCardFrontFile, setVerificationPageStep, 
} from '../../../redux/kycVerification/kycVerificationAction';
import { verifiedStatusColors } from '../../../redux/kycVerification/kycVerificationReducer';
import VerifyFileInput from '../VerifyFileInput/VerifyFileInput';
import s from './CreditCardModule.module.css';

function CreditCardModule() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const isDataLoading = useSelector(selectIsDataLoading);
  const creditCardFrontFile = useSelector(selectCreditCardFrontFile);
  const creditCardBackFile = useSelector(selectCreditCardBackFile);
  const creditCardFrontData = useSelector(selectCreditCardFrontData);
  const creditCardBackData = useSelector(selectCreditCardBackData);

  const isCardSelected = (cardData, cardFile) => (cardData ? cardData.verifiedStatus !== 'declined' : cardFile);

  const isFrontCardSelected = isCardSelected(creditCardFrontData, creditCardFrontFile);
  const isBackCardSelected = isCardSelected(creditCardBackData, creditCardBackFile);

  const isSkipBtnAvailable = !isFrontCardSelected && !isBackCardSelected;
  const isProcessBtnAvailable = isFrontCardSelected || isBackCardSelected;

  const processBtnClassName = isProcessBtnAvailable ? `${s.processBtn} ${s.readyProcessBtn}` : s.processBtn;
  const processBtnTitle = creditCardFrontData 
    ? (!isFrontCardSelected || !isBackCardSelected) ? t('labels.process') : t('labels.next')
    : t('labels.process');

  const frontColor = creditCardFrontData 
    ? verifiedStatusColors[creditCardFrontData.verifiedStatus] 
    : creditCardFrontFile ? '#09C575' : 'white';

  const backColor = creditCardBackData 
    ? verifiedStatusColors[creditCardBackData.verifiedStatus] 
    : creditCardBackFile ? '#09C575' : 'white';

  const handleBackBtn = () => dispatch(setVerificationPageStep('idCard'));
  const handleSkipBtn = () => {
    if (!creditCardFrontData) dispatch(setCreditCardFrontFile(null));
    if (!creditCardBackData) dispatch(setCreditCardBackFile(null));
    dispatch(setVerificationPageStep('selfie'));
  };

  const handleProcessBtn = () => {
    if (isProcessBtnAvailable) dispatch(setVerificationPageStep('selfie'));
  };

  const handleCreditCardFrontChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      dispatch(setCreditCardFrontFile(files[0]));
      dispatch(setCreditCardFrontData(null));
    }
  };

  const handleCreditCardBackChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      dispatch(setCreditCardBackFile(files[0]));
      dispatch(setCreditCardBackData(null));
    }
  };

  if (isDataLoading) {
    return (
      <>Loading</>
    );
  }
  
  return (
    <div className={s.wrapper}>
      <div className={s.uploadInputsWrapper}>
        <VerifyFileInput
          type="credit-card-front"
          labelTitle={t('verification.creditCardFront')}
          borderTitle={t('verification.creditCardFrontBorder')}
          statusColor={frontColor}
          onChange={handleCreditCardFrontChange}
        />
        <VerifyFileInput
          type="credit-card-back"
          labelTitle={t('verification.creditCardBack')}
          borderTitle={t('verification.creditCardBackBorder')}
          statusColor={backColor}
          onChange={handleCreditCardBackChange}
        />
      </div>

      <hr />

      <div className={s.buttonsWrapper}>
        <div className={s.backBtn} onClick={handleBackBtn}>{t('labels.back')}</div>
        <div className={processBtnClassName} onClick={handleProcessBtn}>{processBtnTitle}</div>
        {isSkipBtnAvailable && <span className={s.skipForNowBtn} onClick={handleSkipBtn}>{t('labels.skip_for_now')}</span>}
      </div>
    </div>
  );
}

export default CreditCardModule;
