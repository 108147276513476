import axios from 'axios';
import { toast } from 'react-toastify';
import { ENV } from '../config/config';
import { logout } from '../services/navigatorService';
import { socket } from '../web';

let baseUrl = ENV.serverUrl;
const { minTradeAmtInUSD } = ENV;

async function apiHelper(apiType, path, data, params) {
  if (baseUrl === undefined || !baseUrl) {
    baseUrl = '';
  }
  const xauthtoken = JSON.parse(localStorage.getItem('uToken'));

  if (apiType === 'post' || apiType === 'put' || apiType === 'get' || apiType === 'delete') {
    try {
      const response = await axios({
        method: apiType,
        url: `${baseUrl + path}`,
        data,
        params,
        headers: {
          Authorization: xauthtoken,
        },
      });
      return response;
    } catch (error) {
      if (error.response.data.isTokenExpired) {
        if (localStorage.getItem('uId')) {
          logout(); 
          toast.warning(error.response.data.message);
        }
      } else {
        toast.error(error.response.data.message);
      }
    }
  }
}

function exponentToNumber(getValueInPercentage) {
  if (getValueInPercentage.toString().includes('e') === true) {
    let exponentVal = '';
    if (getValueInPercentage.toString().includes('+') === true) {
      exponentVal = (getValueInPercentage.toString().split('+').slice(1)).join(':');
    } else if (getValueInPercentage.toString().includes('-') === true) {
      exponentVal = (getValueInPercentage.toString().split('-').slice(1)).join(':');
    }
    const value = new Number(getValueInPercentage); // 1e-14
    getValueInPercentage = value.toFixed(exponentVal); // 14
  }

  return getValueInPercentage;
}

async function fetchExchangeRate(fromCoinSymbol, toCoinsSymbolsString) {
  const response = await apiHelper('get', `/api/crypto-compare/get-prices/?currency=${fromCoinSymbol}&symbols=${toCoinsSymbolsString}`);
  const result = response.data;

  if (result.success) {
    return result.data;
  }
  
  toast.error(result.message);
  return null;
}

async function convertAmountToUSD(toCoinSymbol) {
  const responseData = await fetchExchangeRate('USD', toCoinSymbol);
  const currencyRate = responseData[toCoinSymbol] || 0;
  const totalAmtAllowed = minTradeAmtInUSD * currencyRate;
  return totalAmtAllowed;
}

function socketConnection() {
  socket.on('connect', () => {
  });

  socket.emit('emitClientNotificationRequest');
  socket.emit('emitAllClientNotificationRequest');
}

function listUnreadNotification() {
  socket.on('connect', () => {
  });
  socket.emit('emitClientNotificationRequest');
}

const nonNegativeAmount = (amount) => Math.max(amount, 0);

export const isSuccessResponse = (response) => response && response.data && response.status === 200;

export {
  apiHelper, exponentToNumber, socketConnection, listUnreadNotification, convertAmountToUSD, fetchExchangeRate, nonNegativeAmount,
};
