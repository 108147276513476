// layouts
import PublicLayout from './layouts/PublicLayout';
import AuthLayout from './layouts/AuthLayout';
import PrivateLayout from './layouts/PrivateLayout';

// Public Components
import TradePage from './pages/TradePage';
import PortfolioStarbitrex from './pages/PortfolioStarbitrex';
import AdditionalSecurityStarbitrex from './pages/AdditionalSecurityStarbitrexPage';
import ExchangeStarbitrex from './pages/ExchangeStarbitrex/ExchangeStarbitrex';
import TransactionsStarbitrex from './pages/TransactionsStarbitrexPage';
import CoinAvtivityStarbitrex from './pages/CoinAvtivityStarbitrexPage';
import FiatCoinAvtivityStarbitrex from './pages/FiatCoinActivityStarbitrex';
import ChangePassword from './pages/ChangePasswordPage';

// Auth Components
import LoginStarbitrex from './pages/LoginStarbitrex';
import RestoreStarbitrex from './pages/RestoreStarbitrex';
import TradeSpotStarbitrex from './pages/TradeSpotStarbitrexPage';

// Private Components
import WithdrawCryptoPage from './pages/WithdrawCryptoPage';
import WithdrawFiatPage from './pages/WithdrawFiatPage/WithdrawFiatPage';
import DepositPage from './pages/DepositPage';
import Setting from './pages/Setting';
import Staking from './pages/Staking';
import ChartPage from './pages/ChartPage';
import { VerifyAccount } from './pages/VerifyAccount';
import Verification from './pages/Verification/Verification';

const routes = [
  {
    path: '/trade/:coins', exact: true, name: 'Trade', layout: PublicLayout, component: TradePage,
  },
  {
    path: '/trade-spot/:coins', exact: true, name: 'Trade Spot', layout: PublicLayout, component: TradeSpotStarbitrex,
  },
  // {
  //   path: '/backup', exact: true, name: 'Backup', layout: PublicLayout, component: BackupStarbitrex,
  // },
  {
    path: '/additional-security', exact: true, name: 'Addition Security', layout: PublicLayout, component: AdditionalSecurityStarbitrex,
  },
  // {
  //   path: '/personalize', exact: true, name: 'Personalize', layout: PublicLayout, component: PersonalizeStarbitrex,
  // },
  // {
  //   path: '/leverage-margin', exact: true, name: 'LeverageMargin', layout: PublicLayout, component: LeverageMargin,
  // },

  {
    path: '/register', exact: true, name: 'Register', layout: AuthLayout, component: LoginStarbitrex,
  },
  {
    path: '/login', exact: true, name: 'Login', layout: AuthLayout, component: LoginStarbitrex,
  },
  {
    path: '/verify-account/:token', exact: true, name: 'Verify', layout: AuthLayout, component: VerifyAccount,
  },
  {
    path: '/restore', exact: true, name: 'Restore', layout: AuthLayout, component: RestoreStarbitrex,
  },
  {
    path: '/change-password', exact: true, name: 'Change Password', layout: PrivateLayout, component: ChangePassword,
  },

  {
    path: '/portfolio', exact: true, name: 'Portfolio', layout: PrivateLayout, component: PortfolioStarbitrex,
  },
  {
    path: '/', exact: true, name: 'Portfolio', layout: PrivateLayout, component: PortfolioStarbitrex,
  },
  {
    path: '/exchange/:symbol', exact: true, name: 'Exchange', layout: PrivateLayout, component: ExchangeStarbitrex,
  },
  {
    path: '/fiat-exchange/:symbol', exact: true, name: 'Exchange', layout: PrivateLayout, component: ExchangeStarbitrex,
  },
  {
    path: '/transactions', exact: true, name: 'Transactions', layout: PrivateLayout, component: TransactionsStarbitrex,
  },
  {
    path: '/activity/:coin', exact: true, name: 'Coin Avtivity', layout: PrivateLayout, component: CoinAvtivityStarbitrex,
  },
  {
    path: '/fiat-activity/:coin', exact: true, name: 'Fiat Coin Avtivity', layout: PrivateLayout, component: FiatCoinAvtivityStarbitrex,
  },
  // {
  //   path: '/create-password', exact: true, name: 'CreatePassword', layout: PrivateLayout, component: CreatePasswordStarbitrex,
  // },
  {
    path: '/withdraw-crypto/:symbol', exact: true, name: 'Withdraw', layout: PrivateLayout, component: WithdrawCryptoPage,
  },
  {
    path: '/withdraw-fiat/:symbol', exact: true, name: 'Withdraw Fiat', layout: PrivateLayout, component: WithdrawFiatPage,
  },
  {
    path: '/deposit/:symbol', exact: true, name: 'Deposit', layout: PrivateLayout, component: DepositPage,
  },
  {
    path: '/profile-setting', exact: true, name: 'Settings', layout: PrivateLayout, component: Setting,
  },
  {
    path: '/verification', exact: true, name: 'Verification', layout: PrivateLayout, component: Verification,
  },
  {
    path: '/simple-earn-staking', exact: true, name: 'Staking', layout: PrivateLayout, component: Staking,
  },
  {
    path: '/chart', exact: true, name: 'Chart Page', layout: PrivateLayout, component: ChartPage,
  },
];

export default routes;

// ===========
// EXTRA OLD ROUTES
// ===========

/* <Route path="trade/:coins" element={<TradePage />} />
<Route path="futures" element={<EarnPage />} />
<Route path="market" element={<MarketPage />} />

<Route path="deposit-fiat" element={<DepositFiatPage />} />
<Route path="deposit-fiat2" element={<Depo;;sitFiat2Page />} />
<Route path="convert-history" element={<ConvertHistoryPage />} />
<Route path="forgot-password2" element={<ForgotPassword2 />} />  */
