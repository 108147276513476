import {
  React, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cards from 'react-credit-cards';
import { GetCoinImg } from '../../helpers/getCoinImg';
import { getFiatCurrency } from '../../redux/currencies/currencyActions';
import { getNetwork } from '../../redux/networks/networkActions';
import { getWallets } from '../../redux/addresses/externalWalletActions';
import { getCards } from '../../redux/cards/creditCardActions';
import { resetWithdrawFee } from '../../redux/withdrawFee/withdrawFeeActions';
import { clearWithdraw } from '../../redux/externalTransactions/externalTransactionActions';
import {
  clearFiatWithdraw,
  submitFiatWithdraw,
} from '../../redux/externalFiatTransactions/externalFiatTransactionActions';
import {
  clearBankWithdraw,
  submitBankWithdraw,
} from '../../redux/externalBankTransactions/externalBankTransactionActions';
import GetAccountData from '../../components/GetAccountData';
import { getAccount } from '../../redux/account/accountActions';
import FullPageLoader from '../../components/FullPageLoader/fullPageLoader';
import 'react-credit-cards/es/styles-compiled.css';
import UserCards from '../../components/WithdrawFiat/UserCards';
import AddCard from '../../components/WithdrawFiat/AddCard';
import AddBankAccount from '../../components/WithdrawFiat/AddBankAccount';
import UserBankAccounts from '../../components/WithdrawFiat/UserBankAccounts';
import { nonNegativeAmount, socketConnection } from '../../redux/apiHelper';
import { FrequentlyAskedQuestion } from '../../components/FrequentlyAskedQuestion/FrequentlyAskedQuestion';
import { NewCardForm, SavedCardForm } from './components';
import { getBankAccounts } from '../../redux/bankAccounts/bankAccountActions';


function WithdrawFiatPage() {
  const { t } = useTranslation();
  const { symbol } = useParams();
  const [showCard, setShowCard] = useState(false);
  const [showBank, setShowBank] = useState(false);
  const [showAddCards, setShowAddCards] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);

  const handleCloseCard = () => setShowCard(false);
  const handleShowCard = () => setShowCard(true);
  const handleShowBank = () => setShowBank(true);
  const handleCloseBank = () => setShowBank(false);
  const handleCloseCC = () => setShowAddCards(false);
  const handleShowBankAccount = () => setShowBankAccount(true);
  const handleCloseBankAccount = () => setShowBankAccount(false);

  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [, setSelectedNetwork] = useState([]);
  const [, setSelectedAddress] = useState([]);
  const [, setShowWithdraw] = useState(false);
  const [haveCoins, setHaveCoins] = useState(0);
  const [withdrawCoins, setWithdrawCoins] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showConfirmationCC, setShowConfirmationCC] = useState(false);
  const [showConfirmationBank, setShowConfirmationBank] = useState(false);
  const [, setDepositCoin] = useState(true);
  const [depositCoin2, setDepositCoin2] = useState(true);
  const [depositCoin3, setDepositCoin3] = useState(false);
  const [isNewCardTab, setIsNewCardTab] = useState(true);
  const [isSavedCardTab, setIsSavedCardTab] = useState(false);
  const [isShownAddCardBtn, setIsShownAddCardBtn] = useState(false);

  const defaultCardOptions = {
    month: '',
    year: '',
    number: '',
    name: '',
    cvc: '',
    expiry: '',
    focus: '',
  };

  const [cardOptions, setCardOptions] = useState(defaultCardOptions);

  const [selectedCard, setSelectedCard] = useState();
  const [selectedBank, setSelectedBank] = useState([]);
  const [withdrawFieldsIsNotValid, setWithdrawFieldsIsNotValid] = useState({
    number: false,
    numberError: '',
    name: false,
    nameError: '',
    cvc: false,
    cvcError: '',
  });
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [amountWithrawError, setAmountWithrawError] = useState();

  const handleCloseConfirmationCC = () => setShowConfirmationCC(false);
  const handleCloseConfirmationBank = () => setShowConfirmationBank(false);
  const handleShowConfirmationCC = () => setShowConfirmationCC(true);
  const handleShowConfirmationBank = () => setShowConfirmationBank(true);

  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const creditCards = useSelector((state) => state.creditCards);
  const bankAccounts = useSelector((state) => state.bankAccounts?.bankAccounts);
  const userId = useSelector((state) => state.user?.user?._id);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const withdrawn = useSelector((state) => state.externalTransactions?.withdrawn);
  const fiatWithdrawn = useSelector((state) => state.externalFiatTransactions?.fiatWithdrawn);
  const bankWithdrawn = useSelector((state) => state.externalBankTransactions?.bankWithdrawn);
  const error = useSelector((state) => state.externalTransactions?.error);
  const withdrawMsg = useSelector((state) => state.externalTransactions?.transaction?.message);

  const clickNewCardTab = () => {
    setIsNewCardTab(true);
    setIsSavedCardTab(false);
    setCardOptions(defaultCardOptions);
  };

  const clickSavedCardTab = () => {
    setIsNewCardTab(false);
    setIsSavedCardTab(true);
    setCardOptions(defaultCardOptions);
  };

  const handleWithdrawCard = async () => {
    if (parseFloat(haveCoins) <= 0) {
      Swal.fire({
        text: t('messages.min_withdrawal_warning'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        userId,
        currencyId: selectedCurrency?._id,
        sendToCard: selectedCard?.card || selectedCard?.number,
        deducted: parseFloat(withdrawCoins).toPrecision(8),
        coins: withdrawCoins.toString(),
      };
      setLoader(true);
      await dispatch(submitFiatWithdraw(data)).then(() => setLoader(false));
    }
  };

  const handleWithdrawBank = async () => {
    if (parseFloat(haveCoins) <= 0) {
      Swal.fire({
        text: t('messages.min_withdrawal_warning'),
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const data = {
        userId,
        currencyId: selectedCurrency?._id,
        sendToIban: selectedBank?.iban,
        sendToAddress: selectedBank?.bankAddress,
        sendToAccountNumber: selectedBank?.accountNumber,
        sendToSwiftCode: selectedBank?.swiftCode,
        deducted: parseFloat(withdrawCoins).toPrecision(8),
        coins: withdrawCoins.toString(),
      };

      setLoader(true);
      dispatch(submitBankWithdraw(data)).then(
        () => setLoader(false),
      );

      // connect socket and emit notification
      await socketConnection();
    }
  };

  const clearWithdrawFee = () => {
    dispatch(resetWithdrawFee());
  };

  const handleSelectedCoin = (coin) => {
    setSelectedCurrency(coin);
    const selectedAmount = amounts?.find((row) => row.currencyId === coin._id);
    if (coin) setHaveCoins(nonNegativeAmount(selectedAmount.amount + (selectedAmount.credit_amount ?? 0)));
    setSelectedNetwork([]);
    setSelectedAddress([]);
    setSelectedCard([]);
    setSelectedBank([]);
    setShowWithdraw(false);
    setWithdrawCoins(0);
    clearWithdrawFee();
  };

  const handleWithdrawLimitFiat = () => {
    const validNumber = /^\d*\.?\d*$/;

    if (
      !withdrawCoins.toString().match(validNumber)
      || parseFloat(withdrawCoins) > parseFloat(parseFloat(haveCoins))
    ) {
      let textMesasge = '';
      const selectedCurrSymbol = selectedCurrency && selectedCurrency?.symbol ? selectedCurrency?.symbol : '';
      if (!withdrawCoins.toString().match(validNumber)) {
        textMesasge = t('messages.invalid_number_warning');
      } else if (parseFloat(withdrawCoins) > parseFloat(haveCoins)) {
        textMesasge = t('messages.not_enough_balance_for_withdraw_warning', { current_balance: `${haveCoins} ${selectedCurrSymbol}`, withdraw_amount: `${parseFloat(withdrawCoins)} ${selectedCurrSymbol}` });
      }

      setWithdrawCoins(0);
      Swal.fire({
        text: textMesasge,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    }
  };

  const depositCoinHandler2 = () => {
    setDepositCoin(false);
    setDepositCoin2(true);
    setDepositCoin3(false);
  };
  const depositCoinHandler3 = () => {
    setDepositCoin(false);
    setDepositCoin2(false);
    setDepositCoin3(true);
  };

  useEffect(() => {
    dispatch(getFiatCurrency());
    dispatch(getNetwork());
    if (userId) {
      dispatch(getWallets(userId));
      dispatch(getCards(userId));
      dispatch(getBankAccounts(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(clearWithdraw());
      dispatch(clearFiatWithdraw());
      dispatch(clearBankWithdraw());
    } else if (withdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: withdrawMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearWithdraw());
        dispatch(clearFiatWithdraw());
        dispatch(clearBankWithdraw);
        setSelectedCurrency([]);
        handleSelectedCoin(null);
      });
    } else if (fiatWithdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: withdrawMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearFiatWithdraw());
        setWithdrawCoins(0);
      });
    } else if (bankWithdrawn) {
      setLoader(false);
      Swal.fire({
        title: t('labels.success'),
        text: withdrawMsg,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        dispatch(getAccount(userId));
        dispatch(clearBankWithdraw());
        setSelectedCurrency([]);
        handleSelectedCoin(null);
      });
    }
  }, [withdrawn, fiatWithdrawn, bankWithdrawn, error]);

  useEffect(() => {
    if (currencyData) {
      const found = currencyData?.find((currency) => currency.symbol === symbol);
      handleSelectedCoin(found);
    }
  }, [currencyData, amounts]);

  useEffect(() => {
    if (isNewCardTab) {
      const canAddNewCard = Object.keys(cardOptions).every((key) => {
        if (cardOptions[key].length) return true;
  
        return false;
      });

      if (
        cardOptions.name.includes(' ') 
        && (cardOptions.number.length === 16 || cardOptions.number.length === 19)
        && (cardOptions.cvc.length === 3 || cardOptions.cvc.length === 4) 
        && cardOptions.year
        && cardOptions.month
        && !withdrawFieldsIsNotValid.name 
        && !withdrawFieldsIsNotValid.number 
        && !withdrawFieldsIsNotValid.cvc) {
        setAllFieldsValid(true);
        return;
      } 

      setAllFieldsValid(false);
      setSelectedCard(canAddNewCard ? cardOptions : {});
      setIsShownAddCardBtn(canAddNewCard);
    }
  }, [isNewCardTab, cardOptions, withdrawFieldsIsNotValid, setAllFieldsValid]);

  useEffect(() => {
    if (isSavedCardTab && selectedCard && Object.keys(selectedCard).length) {
      const cardData = {
        number: selectedCard.card,
        name: selectedCard.name,
        cvc: selectedCard.cvv,
        month: selectedCard.expiredMonth,
        year: selectedCard.expiredYear,
        expiry: `${selectedCard.expiredMonth}/${selectedCard.expiredYear}`,
        focus: '',
      };

      setCardOptions(cardData);
    }
  }, [selectedCard]);

  const hadleAmountWithdraw = (event) => {
    const amountRegex = /^$|^[0-9.]+$/;
    if (!amountRegex.test(event.target.value)) {
      setAmountWithrawError('Amount must contains only numbers');
      return;
    }
    setWithdrawCoins(event.target.value);
    setAmountWithrawError(null);
  };

  return loader ? (
    <FullPageLoader />
  ) : (
    <>
      <section className="header-padding withdrawal-page">
        <GetAccountData />
        <div className="container-fluid custom-box padding50">
          <div className="d-flex justify-content-center align-items-center flex-md-row flex-column">
            <div className="d-flex align-items-center mb-lg-0 mb-3">
              <i className="fa fa-angle-left me-lg-4 me-3 left-angle" />
              <h3 className="mb-0 text-light" style={{ textTransform: 'uppercase' }}>{t('labels.withdrawal')}</h3>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-8">
              <div className="deposit-col">
                <div className="deposit-coin">
                  <button type="button" className="payopt" onClick={depositCoinHandler2}>
                    {t('labels.credit_card')}
                  </button>
                  <button type="button" className="payopt" onClick={depositCoinHandler3}>
                    {t('labels.bank')}
                  </button>
                  <div className="deposit-coin3">
                    {depositCoin3 && (
                      <form>
                        {depositCoin3 && (
                          <div className="dropdown deposit-dropdown">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div className="d-flex justify-content-between">
                                <p className="coin-name">
                                  {selectedCurrency?.symbol ? selectedCurrency?.symbol : symbol}
                                </p>
                                <div className="coin-details d-flex align-items-center">
                                  <p className="detail">
                                    (
                                    {selectedCurrency?.name ? selectedCurrency?.name : t('labels.select')}
                                    )
                                  </p>
                                  <p className="dd-arrow" />
                                </div>
                              </div>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {currencyData && currencyData.length > 0 && currencyData.length ? (
                                currencyData
                                && currencyData.length > 0
                                && currencyData
                                  .filter((row) => row?.isFiat === true)
                                  .map((currency) => (
                                    <li
                                      key={currency._id}
                                      onClick={() => handleSelectedCoin(currency)}
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={() => {
                                          handleSelectedCoin(currency);
                                        }}
                                      >
                                        <img
                                          src={GetCoinImg(currency?.symbol)}
                                          alt=""
                                          className="img-fluid coin-img pe-1"
                                        />
                                        {currency.name}
                                      </a>
                                    </li>
                                  ))
                              ) : (
                                <p className="text-light">{t('messages.no_currencies_found')}</p>
                              )}
                            </ul>
                          </div>
                        )}

                        <div className="row">
                          <div className="wallet-address">
                            <div className="address-add">
                              <p className="mb-0 text-white">{t('labels.bank_account')}</p>
                              <button
                                type="button"
                                onClick={handleShowBank}
                                className="btn add-address-btn"
                              >
                                {t('labels.add')}
                              </button>
                            </div>
                            <div className="dropdown wallet-address-dd">
                              <button
                                className="btn dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {selectedBank && selectedBank.bank
                                  ? selectedBank.name
                                  : t('labels.select_bank_account')}
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                {bankAccounts
                                && bankAccounts.filter(
                                  (bank) => bank && bank.currencyId === selectedCurrency?._id,
                                ).length ? (
                                    bankAccounts
                                  && bankAccounts
                                    .filter(
                                      (bank) => bank && bank.currencyId === selectedCurrency?._id,
                                    )
                                    .map((bank) => (
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          key={bank._id}
                                          onClick={() => setSelectedBank(bank)}
                                        >
                                          {bank.name}
                                        </a>
                                      </li>
                                    ))
                                  ) : (
                                    <li>
                                      <a className="dropdown-item">{t('messages.no_addresses_added')}</a>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />
                        {selectedBank && selectedBank.status ? (
                          <>
                            <div className="amount-withdrawal">
                              <div className="withdrawal-amount">
                                <p className="mb-0 text-white">{t('labels.amount_withdrawable')}</p>
                                <p className="text-white-light mb-0">
                                  {t('labels.amount')}
                                  {' '}
                                  {haveCoins}
                                  {' '}
                                  {selectedCurrency && selectedCurrency?.symbol
                                    ? selectedCurrency?.symbol
                                    : ''}
                                </p>
                              </div>
                              <div className="input-all">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    setWithdrawCoins(e.target.value);
                                  }}
                                  onBlur={() => handleWithdrawLimitFiat()}
                                  value={withdrawCoins}
                                />
                                <button
                                  type="button"
                                  className="btn text-green"
                                  onClick={() => {
                                    setWithdrawCoins(
                                      parseFloat(haveCoins) - withdrawCoins / 100 >= 0
                                        ? parseFloat(haveCoins) - withdrawCoins / 100
                                        : 0,
                                    );
                                  }}
                                >
                                  {t('labels.all')}
                                </button>
                              </div>
                            </div>
                            <br />
                            <button
                              type="button"
                              className="btn enter-btn3"
                              onClick={() => handleShowConfirmationBank()}
                              disabled={loader}
                            >
                              {t('labels.enter')}
                            </button>
                          </>
                        ) : null}
                      </form>
                    )}
                  </div>

                  {depositCoin2 && (
                  <div className="deposit-coin2">
                    <div className="dropdown deposit-dropdown">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ width: '500px' }}
                      >
                        <div className="d-flex justify-content-between">
                          <p className="coin-name">
                            {selectedCurrency?.symbol ? selectedCurrency?.symbol : symbol}
                          </p>
                          <div className="coin-details d-flex align-items-center">
                            <p className="detail">
                              (
                              {selectedCurrency?.name ? selectedCurrency?.name : 'Select'}
                              )
                            </p>
                            <p className="dd-arrow" />
                          </div>
                        </div>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {currencyData && currencyData.length ? (
                          currencyData.map((currency) => (
                            <li key={currency._id} onClick={() => handleSelectedCoin(currency)}>
                              <a
                                className="dropdown-item"
                                onClick={() => {
                                  handleSelectedCoin(currency);
                                }}
                              >
                                <img
                                  src={GetCoinImg(currency?.symbol)}
                                  alt=""
                                  className="img-fluid coin-img pe-1"
                                />
                                {currency.name}
                              </a>
                            </li>
                          ))
                        ) : (
                          <p className="text-light">{t('messages.no_currencies_found')}</p>
                        )}
                      </ul>
                    </div>
                    <div className="cardf">
                      <div onClick={() => setCardOptions((prev) => ({ ...prev, focus: prev.focus !== 'cvc' ? 'cvc' : 'name' }))}>
                        <Cards
                          number={cardOptions.number}
                          name={cardOptions.name}
                          expiry={cardOptions.expiry}
                          cvc={cardOptions.cvc}
                          focused={cardOptions.focus}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="sub-tab__btn-row">
                      <button type="button" className={isNewCardTab && 'active'} onClick={clickNewCardTab}>{t('labels.new_card')}</button>
                      <button type="button" className={isSavedCardTab && 'active'} onClick={clickSavedCardTab}>{t('labels.from_saved')}</button>
                    </div>
                    {isNewCardTab 
                    && (
                    <NewCardForm 
                      setCardOptions={setCardOptions} 
                      isBtnShown={isShownAddCardBtn} 
                      cardOptions={cardOptions} 
                      userId={userId} 
                      currencyId={selectedCurrency?._id} 
                      isNotValidFields={withdrawFieldsIsNotValid}
                      setIsNotValidFields={setWithdrawFieldsIsNotValid}
                    />
                    )}
                    {isSavedCardTab && <SavedCardForm selectedCurrency={selectedCurrency} selectedCard={selectedCard} handleShowCard={handleShowCard} creditCards={creditCards} setSelectedCard={setSelectedCard} />}                 
                    <div style={{ marginTop: '12px', maxWidth: '500px' }}>
                      {(selectedCard && Object.keys(selectedCard).length) ? (
                        <div className="amount-withdrawal">
                          <div className="withdrawal-amount" style={{ marginBottom: '7px' }}>
                            <p className="mb-0 text-white">{t('labels.amount_withdrawable')}</p>
                            <p className="text-white-light mb-0">
                              {t('labels.amount')}
                              {' '}
                              {haveCoins}
                              {' '}
                              {selectedCurrency && selectedCurrency?.symbol
                                ? selectedCurrency?.symbol
                                : ''}
                            </p>
                          </div>
                          <div className="input-all">
                            <input
                              type="text"
                              onChange={hadleAmountWithdraw}
                              onBlur={() => handleWithdrawLimitFiat()}
                              value={withdrawCoins}
                            />
                            {allFieldsValid && (
                            <button
                              type="button"
                              className="btn text-green"
                              onClick={() => {
                                setWithdrawCoins(
                                  parseFloat(haveCoins) - withdrawCoins / 100 >= 0
                                    ? parseFloat(haveCoins) - withdrawCoins / 100
                                    : 0,
                                );
                              }}
                            >
                              {t('labels.all')}
                            </button>
                            ) }
                          </div>
                          {amountWithrawError && <p style={{ color: 'red' }}>{amountWithrawError}</p>}
                        </div>
                      ) : null}          
                    </div>
                    <div>
                      {selectedCard && parseFloat(withdrawCoins) ? (
                        <button
                          type="button"
                          className="btn enter-btn4"
                          onClick={() => handleShowConfirmationCC()}
                          disabled={loader}
                          style={{ margin: '30px 0' }}
                        >
                          {t('labels.enter')}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  )}

                </div>
              </div>
            </div>

            <FrequentlyAskedQuestion />
          </div>
        </div>
        <div className="batton" />
      </section>

      {/* ==================================== confirmation modal for CC ============================== */}

      <Modal
        Modal
        className="withdrawal-modal"
        centered
        show={showConfirmationCC}
        onHide={handleCloseConfirmationCC}
      >
        <Modal.Header className="modal-main-heading" closeButton>
          <div className="modal-main-heading-content">
            <h5 className="modal-title" id="exampleModalLabel" style={{ textTransform: 'uppercase' }}>
              {t('labels.are_you_sure')}
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-modal1 text-light">
            <p>
              {' '}
              <b>{`${t('labels.sending_to_card')}:`}</b>
              {' '}
              <p style={{ wordBreak: 'break-all' }}>{selectedCard?.card || selectedCard?.number}</p>
              {' '}
            </p>
            <p>
              {' '}
              <b>{`${t('labels.sending')}:`}</b>
              {' '}
              {withdrawCoins}
              {' '}
              {selectedCurrency?.symbol}
              {' '}
            </p>
            <br />
            <div className="d-flex justify-content-right">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  handleWithdrawCard();
                  handleCloseConfirmationCC();
                }}
              >
                {t('labels.send')}
              </button>
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={() => {
                  handleCloseConfirmationCC();
                }}
              >
                {t('labels.confirm')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== confirmation modal for Bank Account ============================== */}

      <Modal
        Modal
        className="withdrawal-modal"
        centered
        show={showConfirmationBank}
        onHide={handleCloseConfirmationBank}
      >
        <Modal.Header className="modal-main-heading" closeButton>
          <div className="modal-main-heading-content">
            <h5 className="modal-title" id="exampleModalLabel" style={{ textTransform: 'uppercase' }}>
              {t('labels.are_you_sure')}
            </h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-modal1 text-light">
            <p>
              {' '}
              <b>{`${t('labels.sending_to_bank_account')}:`}</b>
              {' '}
              <p style={{ wordBreak: 'break-all' }}>{selectedBank?.iban}</p>
              {' '}
            </p>
            <p>
              {' '}
              <b>{`${t('labels.sending')}:`}</b>
              {' '}
              {withdrawCoins}
              {selectedCurrency?.symbol}
            </p>
            <p>

              <b>{`${t('labels.deducted_from_your_wallet')}:`}</b>

              {parseFloat(withdrawCoins).toPrecision(8)}
              {' '}
              {selectedCurrency?.symbol}

            </p>
            <br />
            <p>

              <b className="text-danger">{`${t('labels.warning')}:`}</b>
              {t('messages.responsible_trasfer_warning')}
            </p>
            <div className="d-flex justify-content-right">
              <button
                type="button"
                className="btn form-btn text-capitalize"
                onClick={() => {
                  handleWithdrawBank();
                  handleCloseConfirmationBank();
                }}
              >
                {t('labels.yes_send')}
              </button>
              <button
                type="button"
                className="btn btn-danger text-capitalize ms-2"
                onClick={() => {
                  handleCloseConfirmationBank();
                }}
              >
                {t('labels.cancel')}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 1 Bank Account ============================== */}

      <Modal
        className="withdrawal-modal"
        show={showBank}
        onHide={handleCloseBank}
        centered
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="withdrawal-modal1">
            <h4 className="text-white mb-0">{t('labels.bank_accounts')}</h4>
            <div className="text-end">
              <button type="button" onClick={handleShowBankAccount} className="btn add-address-btn">
                {t('labels.add_bank_account')}
              </button>
            </div>
            <br />
            <UserBankAccounts />
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 2 Bank Account =============================== */}

      <Modal
        className="withdrawal-modal"
        show={showBankAccount}
        onHide={handleCloseBankAccount}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <AddBankAccount handleCloseBankAccount={handleCloseBankAccount} />
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 1 CC ============================== */}

      <Modal
        className="withdrawal-modal"
        show={showCard}
        onHide={handleCloseCard}
        centered
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="withdrawal-modal1">
            <h4 className="text-white mb-0">{t('labels.cards')}</h4>
            <UserCards type="fiat" />
          </div>
        </Modal.Body>
      </Modal>

      {/* ==================================== modal 2 CC =============================== */}

      <Modal
        className="withdrawal-modal"
        show={showAddCards}
        onHide={handleCloseCC}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <AddCard handleCloseCC={handleCloseCC} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WithdrawFiatPage;
