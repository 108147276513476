import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import {
  Container, Image, Navbar, Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  faUser,
  faWallet,
  faGear,
  faSignOutAlt,
  faSignIn,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { RefetchTypes } from '@wisecryptoanalysis/starbitrex-libs';
import { ChatContext } from '../../context';
import { SupportButton } from '../SupportButton';
import { LanguageSelector } from '../LanguageSelector';
import Logo from '../../assets/images/ChainPilot.png';
import { getUser, updateUserFieldFromSocket } from '../../redux/users/userActions';
import { LogoutUser } from '../../redux/auth/authActions';
import EXC from '../../assets/images/exchange-svg-icon.svg';
import TDI from '../../assets/images/trade-svg-icon.svg';
import AutoTreaderLogo from '../../assets/images/robotTrade.png';
import {
  displayUnreadNotifications, readNotification, readAllNotification, addNotification, 
} from '../../redux/notifications/notificationActions';
import NotificationSound from '../../assets/audio/notification.wav';
import { socket } from '../../web';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MobileHeader } from './mobileHeader';
import { setNavigator } from '../../services/navigatorService';
import { ENV } from '../../config/config';
import { getAccount, getUserTotalBalance, updateAccountFieldFromSocket } from '../../redux/account/accountActions';

const RefetchByType = {
  [RefetchTypes.ACCOUNT_AMOUNTS]: ({ userId }) => [getUserTotalBalance(), getAccount(userId)],
};

function Header() {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(768);
  const isTab = useMediaQuery(991.5);

  const [path, setPath] = useState('/');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [notification, setNotification] = useState([]);
  const { showChat, hideChat } = useContext(ChatContext);

  const userData = useSelector((state) => state.user?.user);
  const unreadNotifications = useSelector((state) => state.notification.unreadNotifications);
  const token = localStorage.getItem('uToken');

  const handleNotificationResponse = (data) => {
    const audio = new Audio(NotificationSound);
    audio.volume = 0.02;
    audio.play();

    dispatch(addNotification(data));
  };

  const logOut = async (e) => {
    if (e) e.preventDefault();
    socket.emit('stopStreaming');

    await dispatch(LogoutUser(localStorage.getItem('uId').trim()));
    localStorage.removeItem('uToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('uId');
    localStorage.removeItem('isAdminLogged');
    socket.destroy();

    window.location.href = 'https://chainpilot.co.uk/';
  };

  const checkIfUserLogged = (userData) => {
    const isAdminLoggedJSON = localStorage.getItem('isAdminLogged');
    const isAdminLogged = isAdminLoggedJSON && isAdminLoggedJSON.length ? JSON.parse(isAdminLoggedJSON) : false;
    
    if (!userData.isLogin && !isAdminLogged) logOut();
  };

  useEffect(() => {
    setNavigator(history);
  }, [history]);
  
  useEffect(() => {
    if (socket && userData?._id) {
      checkIfUserLogged(userData);

      socket.emit('startStreaming', { userId: userData._id });
      socket.on(`getStackingInvestmentMsg/${userData._id}`, (message) => {
        toast.success(message, {
          autoClose: false,
        });
      });
      socket.on(`onLogoutUser&${userData._id}`, () => logOut());

      dispatch(displayUnreadNotifications(userData._id));
      socket.on(`onNotificationResponse${userData._id}`, handleNotificationResponse);

      socket.on('onIsTradingEnabledUpdated', (isTradingEnabled) => {
        dispatch(updateAccountFieldFromSocket({ isTradingEnabled }));
      });

      socket.on('onIsWithdrawalEnabledUpdated', (isWithdrawalEnabled) => {
        dispatch(updateUserFieldFromSocket({ isWithdrawalEnabled }));
      });

      socket.on('dataRefetchRequest', ({ userId, type }) => {
        if (RefetchByType[type]) {
          RefetchByType[type]({ userId }).forEach((action) => dispatch(action));
        }
      });
    }    
    return () => {
      socket?.off(`onNotificationResponse${userData._id}`);
      socket?.off(`getStackingInvestmentMsg/${userData._id}`);
      socket?.off(`onLogoutUser&${userData._id}`);
    };
  }, [socket, userData]);

  useEffect(() => {
    if (unreadNotifications) {
      setNotification(unreadNotifications);
    }
  }, [unreadNotifications]);

  useEffect(() => {
    const { pathname } = window.location;
    setPath(pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    const uId = JSON.parse(localStorage.getItem('uId'));
    if (uId) {
      dispatch(getUser(uId));
    }
  }, [dispatch]);

  const formatDate = (date) => moment(date).format('LLL');

  const markReadNotification = (notif) => {
    dispatch(readNotification(notif._id, userData._id));
    history.push(notif.redirectUrl);
  };

  const MakeAllNotificationsRead = async () => {
    const ids = notification.map((element) => element._id);
    dispatch(readAllNotification(ids, userData._id));
  };

  return (
    isMobile ? <MobileHeader path={path} showChat={showChat} hideChat={hideChat} userData={userData} /> : (
      <div className="header header-js">
        <Navbar className="navbar navbar-expand-lg navbar-light header1 pb-0" expand="lg">
          <Container fluid>
            <Link className="m-0 navbar-brand" to="https://chainpilot.co.uk/">
              <Image src={Logo} fluid />
            </Link>
            <Navbar.Collapse className="header-navbar-collapse-icon" id="basic-navbar-nav">
              <nav className="navbar-nav">
                <NavLink className="nav-link header-portfolio-navlink" to="/portfolio">
                  <FontAwesomeIcon icon={faUser} className="header-icon" />
                  <span className="navbar-tooltip mobile-none">{t('header.portfolio')}</span>
                  <span className="navbar-link-heading mobile-none2">{t('header.portfolio')}</span>
                </NavLink>
                <Navbar
                  className={path.includes('/activity/') || path.includes('/fiat-activity/')
                    ? 'nav-link dropdown-hover header-trade-dd mobile-none active'
                    : 'nav-link header-trade-dd dropdown-hover mobile-none'}
                >
                  <button type="button" className="dropbtn-hover p-0">
                    <FontAwesomeIcon icon={faWallet} className="header-icon" />
                  </button>
                  <div className="dropdown-content-hover">
                    <Link
                      to="/activity/ETH"
                      className={path.includes('/activity/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.crypto')}
                    </Link>
                    <Link
                      to="/fiat-activity/USD"
                      className={path.includes('/fiat-activity/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.fiat')}
                    </Link>
                  </div>
                </Navbar>
                <NavLink className="nav-link header-exchange-navlink" to="/exchange/BTC">
                  <img src={EXC} className="img-fluid" alt="" />
                  <span className="navbar-tooltip mobile-none">{t('header.exchange')}</span>
                  <span className="navbar-link-heading mobile-none2">{t('header.exchange')}</span>
                </NavLink>
                {/* desktop dropdown */}

                {isTab && (
                  <div className="dropdown header-trade-dd-mobile navbar-nav nav-link mobile-none2">
                    <button
                      className="btn text-white dropdown-toggle p-0"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={TDI} className="header-icon header-trade-icon" alt="" />
                      <span
                        className="navbar-link-heading mobile-none2"
                        style={{ fontWeight: 700 }}
                      >
                        {t('labels.trade')}
                      </span>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <Link
                        to="/trade/BTCUSDT"
                        className={path.includes('/trade/') ? 'active nav-link' : 'nav-link'}
                      >
                        {t('header.futures_trading')}
                      </Link>
                      <Link
                        to="/trade-spot/BTCUSDT"
                        className={path.includes('/trade-spot/') ? 'active nav-link' : 'nav-link'}
                      >
                        {t('header.spot_trading')}
                      </Link>
                      <Link
                        to="/simple-earn-staking"
                        className={path.includes('/simple-earn-staking') ? 'active nav-link' : 'nav-link'}
                      >
                        {t('header.earn')}
                      </Link>
                    </ul>
                  </div>
                )}

                {isTab && (
                <div className="dropdown header-trade-dd-mobile navbar-nav nav-link mobile-none2">
                  <button
                    className="btn text-white dropdown-toggle p-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faWallet} className="header-icon" />
                    <span
                      className="navbar-link-heading mobile-none2"
                      style={{ fontWeight: 700 }}
                    >
                      {t('labels.deposit')}
                    </span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <Link
                      to="/activity/ETH"
                      className={path.includes('/activity/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.crypto')}
                    </Link>
                    <Link
                      to="/fiat-activity/USD"
                      className={path.includes('/fiat-activity/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.fiat')}
                    </Link>
                  </ul>
                </div>
                )}

                <Navbar
                  className={path.includes('/trade/') 
                    || path.includes('/trade-spot/') 
                    || path.includes('/trade-cfd/') 
                    || path.includes('/simple-earn-staking')
                    ? 'nav-link dropdown-hover header-trade-dd mobile-none active'
                    : 'nav-link header-trade-dd dropdown-hover mobile-none'}
                >
                  <button type="button" className="dropbtn-hover p-0">
                    <img src={TDI} className="header-icon header-trade-icon" alt="" />
                  </button>
                  <div className="dropdown-content-hover">
                    <Link
                      to="/trade/BTCUSDT"
                      className={path.includes('/trade/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.futures_trading')}
                    </Link>
                    <Link
                      to="/trade-spot/BTCUSDT"
                      className={path.includes('/trade-spot/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.spot_trading')}
                    </Link>
                    <Link
                      to="/simple-earn-staking"
                      className={path.includes('/simple-earn-staking') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.earn')}
                    </Link>
                  </div>
                </Navbar>
                <div className="navbar-brand" style={{ marginLeft: '50px' }}>
                  <Image src={AutoTreaderLogo} fluid />
                </div>
              </nav>
            </Navbar.Collapse>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            {token && !isMobile ? (
              <nav
                className="header-navbar-collapse-icon navbar-nav header-right-icons"
                style={{ flexDirection: 'row' }}
              >
                {/* <NavLink className="noti-button noti-btn-des" onClick={handleShow}>
                      <FontAwesomeIcon icon={faBell} style={{ fontSize: '25px' }} />
                      <div className="notifiction-badge">
                        {notification.length ? notification.length : '0'}
                      </div>
                    </NavLink> */}
                <Modal className="noti-modal" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t('header.notifications')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {notification.length ? (
                      notification.map((notif) => (
                        <div
                          onClick={() => {
                            markReadNotification(notif);
                          }}
                          className="content active"
                          key={notif._id}
                        >
                          <span className="unread">{t('header.unread')}</span>
                          <h6 className="status">
                            {notif.message}
                          </h6>
                          <p>
                            <span className="date">{formatDate(notif.createdAt)}</span>
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className="no-noti-found">
                        <span className="text-white">{t('header.notifications_not_found')}</span>
                      </div>
                    )}
                  </Modal.Body>
                  {notification.length ? (
                    <Modal.Footer style={{ border: '0' }}>
                      <button
                        type="button"
                        className="btn-default"
                        style={{ backgroundColor: '#6E7F95' }}
                        onClick={MakeAllNotificationsRead}
                      >
                        {t('header.read_all')}
                      </button>
                    </Modal.Footer>
                  ) : (
                    ''
                  )}
                </Modal>
                <LanguageSelector />
                { !!(userData.isChatEnabled || typeof userData.isChatEnabled === 'undefined') && (<SupportButton onShow={showChat} onHide={hideChat} />) }
                <NavLink className="nav-link header-transactions-navlink" to="/transactions">
                  <FontAwesomeIcon icon={faHistory} className="header-icon" />
                  <span className="navbar-tooltip bg-color">{t('header.history')}</span>
                </NavLink>
                <Navbar
                  className={path.includes('/additional-security/')
                        || path.includes('/change-password/')
                        || path.includes('/profile-setting/')
                    ? 'nav-link dropdown-hover align-content-center header-trade-dd active settings-btn2'
                    : 'nav-link header-trade-dd align-content-center dropdown-hover settings-btn2'}
                >
                  <button type="button" className="dropbtn-hover p-0">
                    <FontAwesomeIcon icon={faGear} className="header-icon" />
                  </button>
                  <div className="dropdown-content-hover">
                    <Link
                      to="/additional-security"
                      className={path.includes('/additional-security/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.security')}
                    </Link>
                    {
                      !ENV.isDEXSystem && (
                        <Link
                          to="/profile-setting/"
                          className={path.includes('/profile-setting/') ? 'active nav-link' : 'nav-link'}
                        >
                          {t('header.profile_setting')}
                        </Link>
                      )
                    }
                    <Link
                      to="/change-password/"
                      className={path.includes('/change-password/') ? 'active nav-link' : 'nav-link'}
                    >
                      {t('header.change_password')}
                    </Link>
                  </div>
                </Navbar>

                <NavLink className="nav-link" to="/logout" onClick={(e) => logOut(e)}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="header-icon" />
                  <span className="navbar-tooltip bg-color">{t('header.logout')}</span>
                </NavLink>
              </nav>
            ) : (
              <nav className="header-navbar-collapse-icon navbar-nav header-right-icons">
                <NavLink className="nav-link" to="/login">
                  <FontAwesomeIcon icon={faSignIn} className="header-icon" />
                  <span className="navbar-tooltip bg-color">{t('header.sign_in')}</span>
                </NavLink>
              </nav>
            )}
          </Container>
        </Navbar>
      </div>
    )
  );
}

export default Header;
