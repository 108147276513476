import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import Slider from 'rc-slider';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { socket } from '../../web';
import { getCurrency } from '../../redux/currencies/currencyActions';
import GetAccountData from '../GetAccountData';
import {
  addSpotOrder,
  clearSpotOrder,
  getUserSpotOrders,
} from '../../redux/spotOrder/spotOrderActions';
import { getAccount } from '../../redux/account/accountActions';
import { nonNegativeAmount } from '../../redux/apiHelper';
import { getPairData } from '../../redux/pairs/pairActions';
import { toFixed, setDynamicPrecision } from '../../helpers/utils';
import { isHighValuePair } from '../Trade/helper';

function BuySell({
  updateRate,
  primaryCoin,
  secondaryCoin,
  setPrimaryCoin,
  setSecondaryCoin,
  today,
  precisions,
  coins,
  isTradingEnabled,
}) {
  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  const { t } = useTranslation();
  const token = localStorage.getItem('uToken');

  const [activeTab, setActiveTab] = useState('Limit');
  const [primAvbl, setPrimAvbl] = useState(0);
  const [secAvbl, setSecAvbl] = useState(0);
  const [rate, setRate] = useState(0);
  const [buyAmt, setBuyAmt] = useState(0);
  const [sellAmt, setSellAmt] = useState(0);
  const [buyTotalAmt, setBuyTotalAmt] = useState(0); // in USDT
  const [sellTotalAmt, setSellTotalAmt] = useState(0); // in USDT
  const [buySlider, setBuySlider] = useState(0);
  const [sellSlider, setSellSlider] = useState(0);
  const [checkType, setCheckType] = useState(1);
  const [getMarketRate, setGetMarketRate] = useState(true);
  const [marketRate, setMarketRate] = useState(0);

  const pairName = useParams().coins;

  const dispatch = useDispatch();
  const location = useLocation();
  const userId = useSelector((state) => state.user?.user?._id);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const pairData = useSelector((state) => state.pairTrade.pair);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const [market, setMarket] = useState(0); // 1=market 0=limit
  const success = useSelector((state) => state.spotOrder?.success);
  const auto = useSelector((state) => state.spotOrder?.auto);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const [buyAmountErr, setBuyAmountErr] = useState('');
  const [sellAmountErr, setSellAmountErr] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const findAmount = (coinId) => parseFloat(nonNegativeAmount(amounts.find((row) => row.currencyId === coinId)?.amount));

  const defaultAssignment = () => {
    const prim = currencyData?.find((row) => row.symbol === 'ETH');
    setPrimaryCoin(prim);
    const sec = currencyData?.find((row) => row.symbol === 'USDT');
    if (amounts) {
      setPrimAvbl(findAmount(prim._id));
      setSecAvbl(findAmount(sec._id));
    }
    setSecondaryCoin(sec);
  };

  useEffect(() => {
    if (pairName && userId && today) {
      socket.emit('getCurrentMarketPriceRequest', { pairName, userId, today });
      socket.on(`getCurrentMarketPriceResponse${pairName}${userId}${today}`, (currentMarketPriceData) => {
        setMarketRate(
          parseFloat(currentMarketPriceData.find((row) => row.symbol === pairName)?.markPrice),
        );
      });
    }

    return () => {
      if (socket) socket.off(`getCurrentMarketPriceResponse${pairName}${userId}${today}`);
    };
  }, [pairName, userId, today]);


  useEffect(() => {
    const { pathname } = location;
    const selectedCoin = pathname.split('/')[2];
    if (selectedCoin) {
      dispatch(getPairData(selectedCoin));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!Object.keys(pairData).length) return;
    const { pathname } = location;
    const selectedCoin = pathname.split('/')[2];
    if (!selectedCoin || !coins.includes(selectedCoin)) {
      defaultAssignment();
      return;
    }
    const prim = pairData.toCurrency;
    const sec = pairData.fromCurrency;
    if (!prim || !sec) {
      defaultAssignment();
      return;
    }
    setPrimaryCoin(prim);
    setSecondaryCoin(sec);
    if (amounts) {
      setPrimAvbl(findAmount(prim._id));
      setSecAvbl(findAmount(sec._id));
    }
  }, [pairData, currencyData, amounts, location.pathname]);
  

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  useEffect(async () => {
    if (primaryCoin?._id && secondaryCoin?._id) {
      setGetMarketRate(true);
      setRate(0);
      setBuyAmt(0);
      setSellAmt(0);
      setSellTotalAmt(0);
      setBuyTotalAmt(0);
      if (amounts) {
        setPrimAvbl(findAmount(primaryCoin._id));
        setSecAvbl(findAmount(secondaryCoin._id));
      }
    }
  }, [primaryCoin, secondaryCoin]);

  useEffect(async () => {
    if (updateRate !== 0 && market === 0) {
      setRate(updateRate.toFixed(precisions[pairName]));
    }
  }, [updateRate]);

  useEffect(async () => {
    if (!!primaryCoin?.symbol && !!secondaryCoin?.symbol && (getMarketRate || rate === 0)) {
      const vall = marketRate;
      if (marketRate) {
        setRate(parseFloat(vall).toFixed(precisions[pairName]));
        setBuyTotalAmt(parseFloat(buyAmt) * vall);
        setSellTotalAmt(parseFloat(sellAmt) * vall);
      } else {
        setRate(0);
      }
    }
  }, [primaryCoin, marketRate, getMarketRate]);

  const setBuyPrice = (amount) => {
    setBuyAmountErr('');
    if (secAvbl) {
      const pr = (amount * rate * 100) / secAvbl;
      const resultValueView = isHighValuePair(pairName) ? pr : toFixed(pr, 2);
      setBuySlider(resultValueView);
      if (pr > 100 || pr < 0) {
        setBuyAmountErr(`${t('messages.invalid_number_warning')}`);
      }
      setBuyAmt(amount);
      setBuyTotalAmt(parseFloat(amount) * rate);
    }
  };

  const setBuyPriceBySlider = (percent) => {
    setBuyAmountErr('');

    if (secAvbl) {
      const convertedRate = secAvbl / rate;
      const valueFromValue = (percent / 100) * Number(convertedRate);
      const buyAmount = parseFloat(valueFromValue) * rate;

      if (valueFromValue > secAvbl || valueFromValue < 0) {
        setBuyAmountErr(`${t('messages.invalid_number_warning')}`);
      }

      setBuyAmt(toFixed(valueFromValue, 4));
      setBuyTotalAmt((buyAmount, 2));
    }
  };

  const setSellPriceBySlider = (percent) => {
    setSellAmountErr('');

    if (primAvbl) {
      const convertedRate = primAvbl * rate;
      const valueFromValue = (percent / 100) * Number(convertedRate);
      const sellAmount = valueFromValue / rate;

      if (sellAmount > primAvbl || valueFromValue < 0) {
        setSellAmountErr(`${t('messages.invalid_number_warning')}`);
      }

      setSellAmt(toFixed(sellAmount, 4));
      setSellTotalAmt(valueFromValue);
    }
  };

  const setSellPrice = (amount) => {
    setSellAmountErr('');
    if (primAvbl) {
      const pr = (amount * 100) / primAvbl;
      const resultValueView = isHighValuePair(pairName) ? pr : toFixed(pr, 2);
      setSellSlider(resultValueView);
      if (pr > 100 || pr < 0) {
        setSellAmountErr(`${t('messages.invalid_number_warning')}`);
      }
      setSellAmt(amount);
      setSellTotalAmt(parseFloat(amount) * rate);
    }
  };

  const clickMarketTab = (marketTab) => {
    if (marketTab) setGetMarketRate(true);
    setMarket(marketTab);
    setBuyAmountErr('');
    setSellAmountErr('');
  };

  const handleShowConfirmation = async (buySell) => {
    if (!isTradingEnabled) {
      return toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    
    setCheckType(buySell);
    setBuyAmountErr('');
    setSellAmountErr('');
    const amount = buySell === 1 ? buyAmt * rate : sellAmt;
    const avail = buySell === 1 ? secAvbl : primAvbl;

    if (pairData.minVolume !== 0 || pairData.maxVolume !== 0) {
      if (pairData.minVolume !== 0 && amount < pairData.minVolume) {
        return Swal.fire({
          text: `Min volume ${pairData.minVolume}`,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }

      if (pairData.maxVolume !== 0 && amount > pairData.maxVolume) {
        return Swal.fire({
          text: `Max volume ${pairData.maxVolume}`,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
      }
    }

    if (parseFloat(amount) >= parseFloat(avail) || parseFloat(amount) <= 0) {
      if (buySell === 1) {
        setBuyAmountErr(`${t('messages.buy_amount_warning')}`);
      } else {
        setSellAmountErr(`${t('messages.sell_amount_warning')}`);
      }
    } else {
      setShowConfirmation(true);
    }
  };


  const handleConvert = () => {
    const qty = checkType === 1 ? buyAmt : sellAmt;
    const amount = checkType === 1 ? buyAmt * rate : sellAmt;
    const sendRate = market === 1 ? marketRate : rate;
    const data = {
      userId,
      spotPair: (primaryCoin?.symbol || 0) + (secondaryCoin?.symbol || 0),
      fromCurrency: checkType === 1 ? secondaryCoin?._id : primaryCoin?._id,
      fromCurrencySymbol: checkType === 1 ? secondaryCoin?.symbol : primaryCoin?.symbol,
      toCurrency: checkType === 1 ? primaryCoin?._id : secondaryCoin?._id,
      toCurrencySymbol: checkType === 1 ? primaryCoin?.symbol : secondaryCoin?.symbol,
      tradeType: checkType === 1 ? 1 : 0,
      tradeStartPrice: parseFloat(sendRate),
      userInvestedAmount: parseFloat(amount),
      marketOrder: market, // 1=market 0=limit,
      tradeEndPrice: parseFloat(checkType === 1 ? buyTotalAmt : sellTotalAmt),
      investedQty: parseFloat(qty),
    };
    dispatch(addSpotOrder(data));
    handleCloseConfirmation();
    setDisableButton(true);
  };

  useEffect(() => {
    if (success) {
      dispatch(clearSpotOrder());
      if (userId) {
        dispatch(getAccount(userId));
        dispatch(getUserSpotOrders(userId));
      }
      setBuyAmt(0);
      setSellAmt(0);
      setSellTotalAmt(0);
      setBuyTotalAmt(0);
      setDisableButton(false);
    }
  }, [success]);

  useEffect(() => {
    if (auto) {
      dispatch(clearSpotOrder());
      if (userId) {
        dispatch(getAccount(userId));
        dispatch(getUserSpotOrders(userId));
      }
    }
  }, [auto]);

  return (
    <>
      {userId ? <GetAccountData /> : null}

      <div className="buy-sell-wrapper">
        <div className="buy-sell-header">
          <h5>{t('labels.buy_sell_bps')}</h5>

          <div className="buy-sell-header-tabs">
            <div
              style={{ cursor: 'pointer' }}
              className={activeTab === 'Limit' ? 'active-tab' : ''}
              onClick={() => {
                clickMarketTab(0);
                setActiveTab('Limit');
                setBuyPrice(0);
                setSellPrice(0);
              }}
            >
              {t('labels.limit')}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className={activeTab === 'Market' ? 'active-tab' : ''}
              onClick={() => {
                clickMarketTab(1);
                setActiveTab('Market');
                setBuyPrice(0);
                setSellPrice(0);
              }}
            >
              {t('labels.market')}
            </div>
          </div>
        </div>

        <div className="buy-sell-content">
          {userId ? <GetAccountData /> : null}

          <div className="buy-sell-btc mt-3">
            <div className="buy-btc">
              <div className="avdl-usdt mb-2">
                <p className="m-0 text-white-light">{t('labels.available_balance')}</p>
                <p className="m-0 text-white">
                  {token ? toFixed(secAvbl, 2) : '--'}

                  {secondaryCoin && secondaryCoin.symbol}
                </p>
              </div>
              <div className="price-amount">
                {market === 0 ? (
                  <div className="custom-form-field mb-3">
                    <div>
                      <label className="text-white-light">{t('labels.price')}</label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="number"
                        value={rate}
                        onChange={(e) => {
                          setRate(Number(e.target.value).toFixed(precisions[pairName]));
                          setGetMarketRate(false);
                        }}
                      />
                      <div>
                        <label style={{ color: '#00D087' }}>{secondaryCoin && secondaryCoin.symbol}</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="custom-form-field mb-3">
                  <div>
                    <label className="text-white-light">{t('labels.amount')}</label>
                  </div>
                  <div className="d-flex">
                    <input
                      type="number"
                      value={buyAmt}
                      onChange={(e) => {
                        setBuyPrice(e.target.value);
                      }}
                      max={secAvbl}
                      min={0}
                    />
                    <div className="d-flex">
                      <label
                        style={{ color: 'gray', marginRight: '5px' }}
                        onClick={() => setBuyPrice(secAvbl / rate)}
                      >
                        {t('labels.max')}
                      </label>
                      <label className="text-white">{primaryCoin && primaryCoin.symbol}</label>
                    </div>
                  </div>
                </div>
                <div>{buyAmountErr ? <span className="errMsg">{buyAmountErr}</span> : ''}</div>
              </div>
              <Slider
                min={0}
                step={0.1}
                marks={marks}
                defaultValue={[0, 25, 50, 75, 100]}
                className="mb-4 range-slider trade-spot-range-slider"
                onChange={(percent) => {
                  if (token) {
                    setBuyPriceBySlider(percent);
                    setBuySlider(percent);
                  }
                }}
                value={buySlider}
              />
              {market === 0 ? (
                <div className="price-amount">
                  <div className="custom-form-field mb-3">
                    <div>
                      <label className="text-white-light">{t('labels.total')}</label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="number"
                        value={parseFloat(buyTotalAmt).toFixed(2)}
                        onChange={(e) => {
                          setBuyPrice(e.target.value / rate);
                        }} 
                      />
                      <div>
                        <label className="text-white">{secondaryCoin && secondaryCoin.symbol}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {token ? (
                <div className="text-center">
                  {disableButton ? (
                    <button type="button" className="btn buy-btc-btn">
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      disabled={disableButton}
                      className="buy-btc-btn"
                      onClick={() => {
                        if (market) setGetMarketRate(true);
                        handleShowConfirmation(1);
                      }}
                    >
                      {t('labels.buy')}
                    </button>
                  )}
                </div>
              ) : (
                <div className=" buy-tabs">
                  <Link to="/register">
                    <button type="button" className="mb-2 register-now">
                      {t('labels.register_now')}
                    </button>
                  </Link>
                  <Link to="/login">
                    <button type="button" className="login-now">
                      {t('labels.login')}
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <div className="sell-btc">
              <div className="avdl-usdt mb-2">
                <p className="m-0 text-white-light">{t('labels.available_balance')}</p>
                <p className="m-0 text-white">
                  {token ? toFixed(primAvbl, setDynamicPrecision(primAvbl)) : '--'}
                  {primaryCoin && primaryCoin.symbol}
                </p>
              </div>
              <div className="price-amount">
                {market === 0 ? (
                  <div className="custom-form-field mb-3">
                    <div>
                      <label className="text-white-light">{t('labels.price')}</label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="number"
                        value={rate}
                        onChange={(e) => {
                          setGetMarketRate(false);
                          setRate(Number(e.target.value).toFixed(precisions[pairName]));
                        }}
                      />
                      <div>
                        <label style={{ color: '#FF2D79' }}>{secondaryCoin && secondaryCoin.symbol}</label>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="custom-form-field mb-3">
                  <div>
                    <label className="text-white-light">{t('labels.amount')}</label>
                  </div>
                  <div className="d-flex">
                    <input
                      type="number"
                      value={sellAmt}
                      onChange={(e) => {
                        setSellPrice(e.target.value);
                      }}
                      max={primAvbl}
                      min={0}
                    />
                    <div style={{ display: 'flex' }}>
                      <label
                        style={{ color: 'gray', marginRight: '5px' }}
                        onClick={() => setSellPrice(primAvbl)}
                      >
                        {t('labels.max')}
                      </label>
                      <label className="text-white">{primaryCoin && primaryCoin.symbol}</label>
                    </div>
                  </div>
                </div>
                <div>{sellAmountErr ? <span className="errMsg">{sellAmountErr}</span> : ''}</div>
              </div>
              <Slider
                min={0}
                step={0.1}
                marks={marks}
                defaultValue={[0, 25, 50, 75, 100]}
                className="mb-4 range-slider trade-spot-range-slider"
                onChange={(percent) => {
                  if (token) {
                    setSellPriceBySlider(percent);
                    setSellSlider(percent);
                  }
                }}
                value={sellSlider}
              />
              {market === 0 ? (
                <div className="price-amount">
                  <div className="custom-form-field mb-3">
                    <div>
                      <label className="text-white-light">{t('labels.total')}</label>
                    </div>
                    <div className="d-flex">
                      <input
                        type="number"
                        value={parseFloat(sellTotalAmt).toFixed(2)}
                        onChange={(e) => {
                          setSellPrice(e.target.value / rate);
                        }}
                      />
                      <div>
                        <label className="text-white">{secondaryCoin && secondaryCoin.symbol}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {token ? (
                <div className="text-center">
                  <button
                    type="button"
                    className="sell-btc-btn"
                    onClick={() => {
                      if (market) setGetMarketRate(true);
                      handleShowConfirmation(2);
                    }}
                  >
                    {t('labels.sell')}
                  </button>
                </div>
              ) : (
                <div className=" buy-tabs">
                  <Link to="/register">
                    <button type="button" className="mb-2 register-now">
                      {t('labels.register_now')}
                    </button>
                  </Link>
                  <Link to="/login">
                    <button type="button" className="login-now">
                      {t('labels.login')}
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <p className="m-0 transfer">Transfer</p> */}
      </div>

      <BuySellModal
        showConfirmation={showConfirmation}
        handleCloseConfirmation={handleCloseConfirmation}
        primaryCoin={primaryCoin}
        secondaryCoin={secondaryCoin}
        handleConvert={handleConvert}
        checkType={checkType}
        rate={rate}
        buyAmt={buyAmt}
        buyTotalAmt={buyTotalAmt}
        sellAmt={sellAmt}
        sellTotalAmt={sellTotalAmt}
      />
    </>
  );
}

export default BuySell;

function BuySellModal({
  showConfirmation,
  handleCloseConfirmation,
  secondaryCoin,
  primaryCoin,
  handleConvert,
  checkType,
  rate,
  buyAmt,
  buyTotalAmt,
  sellAmt,
  sellTotalAmt,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      className="withdraw-details two-factor-auth text-center"
      centered
      backdrop="static"
      show={showConfirmation}
      onHide={handleCloseConfirmation}
    >
      <Modal.Header className="modal-main-heading" closeButton />
      <Modal.Body className="text-white">
        {checkType === 1 ? (
          <>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_price')}</p>
              <p>
                <b>{rate}</b>

                {secondaryCoin && secondaryCoin.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.quantity')}</p>
              <p>
                <b>{buyAmt}</b>

                {primaryCoin && primaryCoin.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_value')}</p>
              <p>
                <b>{parseFloat(buyTotalAmt).toFixed(2)}</b>

                {secondaryCoin && secondaryCoin.symbol}
              </p>
            </div>
            <br />
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_price')}</p>
              <p>
                <b>{rate}</b>

                {secondaryCoin && secondaryCoin.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.quantity')}</p>
              <p>
                <b>{sellAmt}</b>

                {primaryCoin && primaryCoin.symbol}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p>{t('labels.order_value')}</p>
              <p>
                <b>{parseFloat(sellTotalAmt).toFixed(2)}</b>

                {secondaryCoin && secondaryCoin.symbol}
              </p>
            </div>
            <br />
          </>
        )}
        <div className="limit-modal-btns">
          <button
            type="button"
            onClick={() => {
              handleConvert();
            }}
            className="btn confirm"
          >
            {t('labels.confirm')}
          </button>
          <button type="button" onClick={handleCloseConfirmation} className="btn cancel">
            {t('labels.cancel')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
