import { toast } from 'react-toastify';
import { GET_CURRENCY, TOGGLE_STATE } from './currencyTypes';
import { apiHelper } from '../apiHelper';

export const getCurrency = (selectAll) => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/', '', { selectAll: selectAll ?? true });
    if (res && res.data) {
      await dispatch({
        type: GET_CURRENCY,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCryptoCurrency = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/crypto', '');

    if (res && res.data && res.data) {
      dispatch({
        type: GET_CURRENCY,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getFiatCurrency = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/currency/fiat', '');

    if (res && res.data && res.data) {
      dispatch({
        type: GET_CURRENCY,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateCurrencyState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
