import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header/Header';
import { userLastActivity } from '../redux/users/userActions';

function PrivateLayout({ title, children }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user?.user);
  // const [isActive, setActive] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState([]);

  // const toggleClass = () => {
  //   setActive(!isActive);
  // };

  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'ChainPilot';
  }, [title]);

  useEffect(() => {
    if (!localStorage.uToken) {
      window.location.href = '/login';
    }
  }, []);

  return (
    localStorage.uToken
      ? (
        <div className="wrapper">
          <Header />
          {children}
        </div>
      )
      : null

  );
}
export default PrivateLayout;
