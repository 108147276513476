import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { deleteWallet, getWallets } from '../../redux/addresses/externalWalletActions';
import { UserWalletAddressDataTableSchema } from '../../DataTableSchemas/userWalletAddressDataTableSchema';

function UserWalletAddress() {
  const { t } = useTranslation();

  createTheme(
    'solarizedd',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: 'rgba(33, 34, 46, 1)',
      },
      context: {
        background: 'rgba(33, 34, 46, 1)',
        text: '#FFFFFF',
      },
      divider: {
        default: '#fff',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );

  const dispatch = useDispatch();
  const externalWallets = useSelector((state) => state.externalWallets.externalWallets);
  const userId = useSelector((state) => state.user.user._id);

  useEffect(() => {
    if (userId) dispatch(getWallets(userId));
  }, [userId]);

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const copyReferral = () => {
    toast.success(`${t('labels.copied_successfully')}`);
  };
  
  const deleteAction = (id) => {
    Swal.fire({
      title: `${t('messages.delete_record_confirmation')}`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${t('labels.yes')}`,
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteWallet(id));
      }
    });
  };

  const columns = UserWalletAddressDataTableSchema(copyReferral, formatDate, deleteAction);

  return (
    <DataTable
      columns={columns}
      data={externalWallets}
      pagination
      fixedHeader
      persistTableHead
      theme="solarizedd"
      noDataComponent={t('labels.no_records')}
      paginationComponentOptions={{
        rowsPerPageText: t('pagination.rows_per_page'),
        rangeSeparatorText: t('pagination.range_separator'),
      }}
    />
  );
}

export default UserWalletAddress;
