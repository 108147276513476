import ETH from '../assets/images/ETH.svg';
import XRP from '../assets/images/XRP.png';
import USDT from '../assets/images/USDT.png';
import BTC from '../assets/images/BTC.svg';
import LTC from '../assets/images/LTC.svg';
import ADA from '../assets/images/ADA.svg';
import TRX from '../assets/images/TRX.png';
import BCH from '../assets/images/BCH.svg';
import DOGE from '../assets/images/DOGE.svg';
import BNB from '../assets/images/BNB.svg';
import AVAX from '../assets/images/AVAX.svg';
import USDC from '../assets/images/USDC.svg';
import LINK from '../assets/images/LINK.svg';
import EUR from '../assets/images/EUR.png';
import CAD from '../assets/images/CAD.png';
import NZD from '../assets/images/NZD.png';
import AUD from '../assets/images/AUD.png';
import USD from '../assets/images/USD.png';
import ETC from '../assets/images/ETC.png';
import SOL from '../assets/images/SOL.png';
import MATIC from '../assets/images/MATIC.png';
import EOS from '../assets/images/EOS.png';
import ATOM from '../assets/images/ATOM.png';
import XMR from '../assets/images/XMR.png';
import DASH from '../assets/images/DASH.png';
import NEO from '../assets/images/NEO.png';
import GBP from '../assets/images/GBP.png';
import CNF from '../assets/images/CoinNotFound.png';
import JPY from '../assets/images/JPY.png';
import CHF from '../assets/images/CHF.png';
import DOT from '../assets/images/DOT.svg';
import BUSD from '../assets/images/BUSD.svg';
import ICP from '../assets/images/ICP.svg';
import WBTC from '../assets/images/WBTC.svg';
import XLM from '../assets/images/XLM.svg';
import FIL from '../assets/images/FIL.svg';
import VET from '../assets/images/VET.svg';
import THETA from '../assets/images/THETA.svg';
import DAI from '../assets/images/DAI.svg';
import UNI from '../assets/images/UNI.svg';
import AAVE from '../assets/images/AAVE.svg';
import ALGO from '../assets/images/ALGO.svg';
import XTZ from '../assets/images/XTZ.svg';
import CAKE from '../assets/images/CAKE.svg';
import MKR from '../assets/images/MKR.svg';
import MIOTA from '../assets/images/MIOTA.svg';
import BSV from '../assets/images/BSV.svg';
import FTT from '../assets/images/FTT.svg';
import KSM from '../assets/images/KSM.svg';
import COMP from '../assets/images/COMP.svg';
import CRO from '../assets/images/CRO.svg';
import HT from '../assets/images/HT.svg';
import EGLD from '../assets/images/EGLD.svg';
import SNX from '../assets/images/SNX.svg';
import XEM from '../assets/images/XEM.svg';
import CEL from '../assets/images/CEL.svg';

export const GetCoinImg = (name) => {
  if (name === 'ETH') return ETH;
  if (name === 'BTC') return BTC;
  if (name === 'XRP') return XRP;
  if (name === 'USDT') return USDT;
  if (name === 'LTC') return LTC;
  if (name === 'DOGE') return DOGE;
  if (name === 'ADA') return ADA;
  if (name === 'TRX') return TRX;
  if (name === 'BCH') return BCH;
  if (name === 'BNB') return BNB;
  if (name === 'TBNB') return BNB;
  if (name === 'AVAX') return AVAX;
  if (name === 'USDC') return USDC;
  if (name === 'LINK') return LINK;
  if (name === 'EUR') return EUR;
  if (name === 'CAD') return CAD;
  if (name === 'NZD') return NZD;
  if (name === 'AUD') return AUD;
  if (name === 'USD') return USD;
  if (name === 'GBP') return GBP;
  if (name === 'ETC') return ETC;
  if (name === 'SOL') return SOL;
  if (name === 'MATIC') return MATIC;
  if (name === 'EOS') return EOS;
  if (name === 'ATOM') return ATOM;
  if (name === 'XMR') return XMR;
  if (name === 'DASH') return DASH;
  if (name === 'NEO') return NEO;
  if (name === 'JPY') return JPY;
  if (name === 'CHF') return CHF;

  if (name === 'DOT') return DOT;
  if (name === 'BUSD') return BUSD;
  if (name === 'ICP') return ICP;
  if (name === 'WBTC') return WBTC;
  if (name === 'XLM') return XLM;
  if (name === 'FIL') return FIL;
  if (name === 'VET') return VET;
  if (name === 'THETA') return THETA;
  if (name === 'DAI') return DAI;
  if (name === 'UNI') return UNI;
  if (name === 'AAVE') return AAVE;
  if (name === 'ALGO') return ALGO;
  if (name === 'XTZ') return XTZ;
  if (name === 'CAKE') return CAKE;
  if (name === 'MKR') return MKR;
  if (name === 'MIOTA') return MIOTA;
  if (name === 'BSV') return BSV;
  if (name === 'FTT') return FTT;
  if (name === 'KSM') return KSM;
  if (name === 'COMP') return COMP;
  if (name === 'CRO') return CRO;
  if (name === 'HT') return HT;
  if (name === 'EGLD') return EGLD;
  if (name === 'SNX') return SNX;
  if (name === 'XEM') return XEM;
  if (name === 'CEL') return CEL;

  return CNF;
};
