import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons';
import { getChat, getMessage, readMessages } from '../../redux/chat/chatActions';
import NotificationSound from '../../assets/audio/notification.wav';
import './style.css';
import { socket } from '../../web';

const audio = new Audio(NotificationSound);
audio.volume = 0.02;

export function SupportButton({ onShow, onHide }) {
  const userIdJSON = localStorage.getItem('uId');
  const userId = (userIdJSON && userIdJSON !== 'undefined') ? JSON.parse(userIdJSON) : '';
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const chat = useSelector((state) => state.chat);

  const messageCounter = useMemo(() => {
    let counter = 0;
    const { messages } = chat;

    if (messages && messages.length) {
      messages.forEach((message) => {
        if (!message.isRead && message.sender !== userId) counter += 1;
      });
    }

    return counter;
  }, [chat]);

  useEffect(async () => {
    if (userId) await dispatch(getChat(userId)); 
    
    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (chat && chat._id) {
      if (socket && socket.connected && !Object.keys(socket._callbacks).length) {
        socket.on(`getChatMessage/${chat._id}/${chat.user}`, async (response) => {
          await dispatch(getMessage(response));
          audio.play();
        });
      }
    }
  }, [chat]);

  const handleButtonClick = () => {
    if (!isShown) {
      onShow();
      setIsShown(true);
      if (chat._id) dispatch(readMessages({ chatId: chat._id, user: chat.crmUser, userId: chat.user }));

      return;
    }

    onHide();
    setIsShown(false);
  };

  return (
    <div className="nav-link big header-transactions-navlink support-button__container" onClick={() => handleButtonClick()}>
      <FontAwesomeIcon icon={faCommentsDollar} />
      <span className="navbar-tooltip bg-color">{t('labels.support')}</span>
      {!!messageCounter && <span className="support-button__counter">{messageCounter}</span>}
    </div>
  );
}
