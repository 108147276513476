import React, { useEffect } from 'react';
import Header from '../components/Header/Header';
// import Footer from "../LandingPages/Footer/Footer";

function PublicLayout({ title, children }) {
  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'ChainPilot';
  }, [title]);

  return (
    <div className="wrapper">
      <Header />
      {children}
      {/* <Footer /> */}
    </div>
  );
}
export default PublicLayout;
