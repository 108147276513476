import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsDataLoading, selectSelfieData, selectSelfieFile } from '../../../redux/kycVerification/kycVerificationSelectors';
import { setVerificationPageStep, setSelfieFile, setSelfieData } from '../../../redux/kycVerification/kycVerificationAction';
import { DECLINED_STATUS, verifiedStatusColors } from '../../../redux/kycVerification/kycVerificationReducer';
import s from './SelfieModule.module.css';
import VerifyFileInput from '../VerifyFileInput/VerifyFileInput';

function SelfieModule() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const isDataLoading = useSelector(selectIsDataLoading);

  const selfieFile = useSelector(selectSelfieFile);
  const selfieData = useSelector(selectSelfieData);

  const isSelfieSelected = selfieData ? selfieData.verifiedStatus !== DECLINED_STATUS : selfieFile;
  const isSkipBtnAvailable = !isSelfieSelected;
  const isProcessBtnAvailable = isSelfieSelected;

  const processBtnClassName = isProcessBtnAvailable ? `${s.processBtn} ${s.readyProcessBtn}` : s.processBtn;
  const processBtnTitle = selfieData 
    ? selfieData.verifiedStatus === DECLINED_STATUS ? t('labels.process') : t('labels.next')
    : t('labels.process');

  // if we have a backend data -> choose a color via `verifiedStatusColors`
  // if no, then check is file selected -> `#09C575`, if not -> `white`
  const color = selfieData ? verifiedStatusColors[selfieData.verifiedStatus] : selfieFile ? '#09C575' : 'white';

  const handleBackBtn = () => dispatch(setVerificationPageStep('creditCard'));
  const handleSkipBtn = () => {
    if (!selfieData) dispatch(setSelfieFile(null));
    dispatch(setVerificationPageStep('finalStep'));
  };
  
  const handleSelfieInputChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      dispatch(setSelfieFile(files[0]));
      dispatch(setSelfieData(null));
    }
  };

  const handleProcessBtn = () => {
    if (isProcessBtnAvailable) dispatch(setVerificationPageStep('finalStep'));
  };

  if (isDataLoading) {
    return (
      <>Loading</>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.uploadInputsWrapper}>
        <VerifyFileInput
          type="selfie"
          labelTitle={t('verification.selfieLabel')}
          borderTitle={t('verification.selfieBorder')}
          statusColor={color}
          onChange={handleSelfieInputChange}
        />
      </div>

      <hr />

      <div className={s.buttonsWrapper}>
        <div className={s.backBtn} onClick={handleBackBtn}>{t('labels.back')}</div>
        <div className={processBtnClassName} onClick={handleProcessBtn}>{processBtnTitle}</div>
        {isSkipBtnAvailable && <span className={s.skipForNowBtn} onClick={handleSkipBtn}>{t('labels.skip_for_now')}</span>}
      </div>
    </div>
  );
}

export default SelfieModule;
