import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  Tabs, Tab, Table, Modal, InputGroup, FormControl,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLeverageOrder,
  stopLeverageOrder,
  updateLeverageOrder,
} from '../redux/leverageOrder/leverageOrderActions';
import {
  orderHistoryColumnsSchema,
  accountColumnsSchema,
  pendingOrdersColumnsSchema,
  openOrdersColumnsSchema,
} from '../DataTableSchemas/TradePageColumnsSchema';
import { GetCoinImg } from '../helpers/getCoinImg';
import { toFixed } from '../helpers/utils';
import './TradePagesData.css';

function TradeOrdersDatatables({
  selectedRow,
  avbl,
  updateAvbl,
  amounts,
  token,
  currencyData,
  userOrders,
  setSelectedRow,
  setTP,
  setSL,
  TP,
  SL,
  rates,
  setTrailingPrice,
  trailingPrice,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [coinAmount, setCoinAmount] = useState(0);
  const [orderRate, setOrderRate] = useState(0);
  const [rate, setRate] = useState(0);
  const [showLimit, setShowLimit] = useState(false);
  const [showMarket, setShowMarket] = useState(false);
  const [showTPSL, setShowTPSL] = useState(false);
  const [showTrailing, setShowTrailing] = useState(false);
  const [isTradingEnabled, setIsTradingEnabled] = useState(true);
  const account = useSelector((state) => state?.accounts?.account);
  const handleShowTrailing = () => {
    if (isTradingEnabled) {
      setShowTrailing(true);
    } else {
      toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const handleCloseTrailing = () => setShowTrailing(false);
  const handleShowTPSL = () => {
    if (isTradingEnabled) {
      setShowTPSL(true);
    } else {
      toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const handleCloseTPSL = () => {
    setShowTPSL(false);
    setTP(0);
    setSL(0);
  };
  const handleCloseLimit = () => setShowLimit(false);
  const handleCloseMarket = () => setShowMarket(false);
  const handleShowMarket = () => {
    if (isTradingEnabled) {
      setShowMarket(true);
    } else {
      toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
  const [orderId, setOrderId] = useState('');
  const [stopRate, setStopRate] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isTradingEnabled) {
      setShow(true);
    } else {
      toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };



  const [percentage, setPercentage] = useState(`${0}%`);
  createTheme(
    'solarizedd',
    {
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
      background: {
        default: '#13141c',
      },
      context: {
        background: '#13141c',
        text: '#FFFFFF',
      },
      divider: {
        default: '#fff',
      },
      action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
      },
    },
    'dark',
  );

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  function rangeValue(value) {
    const val = value;
    setPercentage(`${val}%`);
    if (avbl) {
      setCoinAmount(avbl * (val / 100));
    }
  }
  useEffect(() => {
    if (account?.isTradingEnabled !== undefined) {
      setIsTradingEnabled(account.isTradingEnabled);
    }
  }, [account.isTradingEnabled]);


  useEffect(() => {
    let PnL = 0;
    userOrders?.filter((row) => (row.futuresOrder === 1 && row.marginType === 0 && row.status === 1)).forEach((ordr) => {
      // PnL
      PnL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
    });

    if (PnL) {
      const vall = avbl + PnL;
      updateAvbl(vall > 0 ? vall : 0);
    }
  }, [rates]);

  const getInnitialMargin = (row) => {
    const val = ((parseFloat(row.qty) * parseFloat(row.tradeStartPrice)) / parseFloat(row.leverage)); // Initial margin
    return (val && !Number.isNaN(val) ? val : 0);
  };

  const getPositionMargin = (row) => {
    // Qty / (entry price x leverage) /  ( Bankruptcy Price = Entry Price * (Leverage / Leverage + 1) )

    const val = toFixed((getInnitialMargin(row) // Initial margin
    + (getInnitialMargin(row) * 0.03)), 2); // 3%  of  Initial margin;

    return `${val && !Number.isNaN(val) ? val : 0} ${row?.fromCurrency?.symbol}`;
  };

  const getUnrealizedPnL = (row) => {
    const pnLResult = {
      value: 0,
      percentage: 0,
      roe: 0,
    };

    const asset = rates ? rates.find((line) => line.symbol === row.pairName) : null;

    if (!asset) return pnLResult;

    const ratePnL = parseFloat(asset ? asset.markPrice : 0);
    const qty = parseFloat(row.qty);
    const tradeStartPrice = parseFloat(row.tradeStartPrice);

    const val = row.tradeType === 1 // buy
      ? qty * (ratePnL - tradeStartPrice)
      : qty * (tradeStartPrice - ratePnL); // sell

    if (!Number.isNaN(val)) {
      const IMR = 1 / row.leverage; // IMR = 1 / leverage
      const initMargin = qty * tradeStartPrice * IMR; // Initial Margin = Quantity x Entry Price x IMR
      const roe = (val / initMargin) * 100; // ROE% = PnL / Initial Margin
      pnLResult.value = val;
      pnLResult.percentage = Math.abs((val / (parseFloat(row.qty) * parseFloat(row.tradeStartPrice))) * 100);
      pnLResult.roe = roe;
    }

    return pnLResult;
  };

  const getLiquidationPrice = (data) => {
    let side = 0;
    if (data.tradeType === 0) { side = -1; } else { side = 1; }
    let wb = 0;
    let tmm = 0;
    let UNPL = 0;
    if (data.marginType === 0 && userOrders.length) { // cross
      wb = (parseFloat(avbl) + parseFloat(data.userInvestedAmount));
      userOrders.filter((row) => (row.futuresOrder === 1 && row.status === 1))
        ?.forEach((ordr) => {
        // tmm
          if (ordr.maintenanceMargin) {
            tmm += ((parseFloat(ordr.qty)
            * parseFloat(rates ? rates?.find((line) => line.symbol === ordr.pairName).markPrice : 0))
            * (parseFloat(ordr.maintenanceMargin) / 100) - parseFloat(ordr.maintenanceAmount));
          }
          // UNPL
          UNPL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
        });
    } else { // isolated
      wb = parseFloat(data.userInvestedAmount);
    }
    const liqPrice = (wb - tmm + UNPL + data.maintenanceAmount - side * data.qty * data.tradeStartPrice) 
      / (data.qty * (data.maintenanceMargin / 100) - side * data.qty);

    return liqPrice || 0;
  };

  const openOrdersColumns = openOrdersColumnsSchema(
    rates,
    getLiquidationPrice,
    getPositionMargin,
    setRate,
    getUnrealizedPnL,
    setSelectedRow,
    handleShowTPSL,
    setTP,
    setSL,
    handleShowTrailing,
    setTrailingPrice,
    setStopRate,
    setOrderId,
    handleShow,
    t,
  );

  const pendingOrdersColumns = pendingOrdersColumnsSchema(
    rates,
    getLiquidationPrice,
    setCoinAmount,
    setSelectedRow,
    setOrderRate,
    handleCloseLimit,
    handleShowMarket,
    setStopRate,
    setOrderId,
    handleShow,
    t,
  );

  const orderHistoryColumns = orderHistoryColumnsSchema(getLiquidationPrice, t);

  const accountColumns = accountColumnsSchema(currencyData, GetCoinImg, t);

  const handleLimit = (val, update = 0) => {
    if (avbl) {
      const validNumber = /^\d*\.?\d*$/;
      if (
        !val.toString().match(validNumber) || parseFloat(val) > (parseFloat(avbl) + update)
      ) {
        Swal.fire({
          text: `${t('messages.invalid_number_warning')}`,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        setCoinAmount(0);
      }
    }
  };

  const updateMarket = () => {
    if (isTradingEnabled) {
      const order = { ...selectedRow };
      const currentMarkPrice = rates.find((line) => line.symbol === order.pairName).markPrice;

      dispatch(startLeverageOrder({ id: order._id, startRate: parseFloat(currentMarkPrice), userId: order.userId }));

      handleCloseMarket();
      handleCloseLimit();
    } else {
      toast.error(t('messages.trading_disabled'), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  const isTPValid = (data, TP) => (data.tradeType ? parseFloat(TP) > data.tradeStartPrice : parseFloat(TP) < data.tradeStartPrice);

  const isSLValid = (data, SL) => (data.tradeType ? parseFloat(SL) < data.tradeStartPrice : parseFloat(SL) > data.tradeStartPrice);

  const allowUpdateCheck = (data) => {
    if (!TP && !SL) {
      return true;
    } if (TP && SL) {
      return isTPValid(data, TP) && isSLValid(data, SL);
    } if (TP) {
      return isTPValid(data, TP);
    } if (SL) {
      return isSLValid(data, SL);
    }
    return false;
  };

  const updateTPSL = () => {
    const data = { ...selectedRow };

    if (allowUpdateCheck(data)) {
      const updatedData = {
        tpsl: true,
        _id: data._id, 
        userInvestedAmount: data.userInvestedAmount,
        tradeStartPrice: data.tradeStartPrice,
        tradeEndPrice: data.tradeEndPrice,
        takeProfitPrice: parseFloat(TP),
        stopLossPrice: parseFloat(SL),
      };
      dispatch(updateLeverageOrder(updatedData));
      handleCloseTPSL();
    } else {
      Swal.fire({
        text: `${t('messages.invalid_tpsl_warning')}`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    }
  };

  const updateTrailing = () => {
    const data = { ...selectedRow };
    const tradeTrailingPrice = parseFloat(trailingPrice);
    const tradeTrailingDifference = parseFloat(trailingPrice) > parseFloat(data.tradeStartPrice)
      ? parseFloat(trailingPrice) - parseFloat(data.tradeStartPrice)
      : parseFloat(data.tradeStartPrice) - parseFloat(trailingPrice);
    
    const updatedData = {
      _id: data._id, 
      userInvestedAmount: data.userInvestedAmount,
      tradeTrailingPrice,
      tradeTrailingDifference,
    };

    dispatch(updateLeverageOrder(updatedData));
    handleCloseTrailing();
  };

  const handleStop = () => {
    dispatch(stopLeverageOrder(orderId, parseFloat(stopRate), false, account.userId));
    setShow(false);
    setOrderId('');
    setStopRate(0);
  };

  return (
    <>
      <Tabs defaultActiveKey="open" className="">
        <Tab eventKey="open" title={t('labels.open_orders')}>
          {userOrders && userOrders.length ? (
            <DataTable
              columns={openOrdersColumns}
              data={userOrders?.filter((row) => row.futuresOrder === 1).filter((row) => row.status === 1)}
              pagination
              fixedHeader
              persistTableHead
              theme="solarizedd"
              noDataComponent={t('labels.no_records')}
              paginationComponentOptions={{
                rowsPerPageText: t('pagination.rows_per_page'),
                rangeSeparatorText: t('pagination.range_separator'),
              }}
            />
          ) : (
            <table className="table empty-table">
              <thead>
                <tr>
                  <th scope="col">{t('labels.contracts')}</th>
                  <th scope="col">{t('labels.qty')}</th>
                  <th scope="col">{t('labels.value')}</th>
                  <th scope="col">{t('labels.entry_price')}</th>
                  <th scope="col">{t('labels.mark_price')}</th>
                  <th scope="col">{t('labels.liq_price')}</th>
                  <th scope="col">{t('labels.position_margin')}</th>
                  <th scope="col">{t('labels.unrealized_pnl')}</th>
                  <th scope="col">{t('labels.daily_realized_pnl')}</th>
                  <th scope="col">{t('labels.tpsl')}</th>
                  <th scope="col">{t('labels.trailing_stop')}</th>
                  <th scope="col">{t('labels.adl')}</th>
                  <th scope="col">{t('labels.close_by')}</th>
                </tr>
              </thead>
              <tbody>
                <td colSpan="13">
                  {token ? (
                    `${t('labels.empty')}`
                  ) : (
                    <div className="graph-table-btns buy-tabs">
                      <Link to="/register">
                        <button
                          type="button"
                          className="mb-2 register-now"
                        >
                          {t('labels.register_now')}
                        </button>
                      </Link>
                      <Link to="/login">
                        <button type="button" className="login-now">
                          {t('labels.login')}
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
              </tbody>
            </table>
          )}
        </Tab>
        <Tab eventKey="pending" title={t('labels.pending_orders')}>
          {userOrders && userOrders.length ? (
            <DataTable
              columns={pendingOrdersColumns}
              data={userOrders?.filter((row) => row.futuresOrder === 1).filter((row) => row.status === 0)}
              pagination
              fixedHeader
              persistTableHead
              theme="solarizedd"
              noDataComponent={t('labels.no_records')}
              paginationComponentOptions={{
                rowsPerPageText: t('pagination.rows_per_page'),
                rangeSeparatorText: t('pagination.range_separator'),
              }}
            />
          ) : (
            <Table responsive className="empty-table">
              <thead>
                <tr>
                  <th scope="col">{t('labels.contracts')}</th>
                  <th scope="col">{t('labels.qty')}</th>
                  <th scope="col">{t('labels.value')}</th>
                  <th scope="col">{t('labels.entry_price')}</th>
                  <th scope="col">{t('labels.mark_price')}</th>
                  <th scope="col">{t('labels.liq_price')}</th>
                  <th scope="col">{t('labels.close_by')}</th>
                </tr>
              </thead>
              <tbody>
                <td colSpan="7">
                  {token ? (
                    `${t('labels.empty')}`
                  ) : (
                    <div className="graph-table-btns buy-tabs">
                      <Link to="/register">
                        <button
                          type="button"
                          className="mb-2 register-now"
                        >
                          {t('labels.register_now')}
                        </button>
                      </Link>
                      <Link to="/login">
                        <button type="button" className="login-now">
                          {t('labels.login')}
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
              </tbody>
            </Table>
          )}
        </Tab>
        <Tab eventKey="history" title={t('labels.order_history')}>
          {userOrders && userOrders.length ? (
            <DataTable
              columns={orderHistoryColumns}
              data={userOrders?.filter((row) => row.futuresOrder === 1).filter((row) => row.status === 2 || row.status === 3 || row.status === 4)}
              pagination
              fixedHeader
              persistTableHead
              theme="solarizedd"
              noDataComponent={t('labels.no_records')}
              paginationComponentOptions={{
                rowsPerPageText: t('pagination.rows_per_page'),
                rangeSeparatorText: t('pagination.range_separator'),
              }}
            />
          ) : (
            <Table responsive className="empty-table">
              <thead>
                <tr>
                  <th scope="col">{t('labels.contracts')}</th>
                  <th scope="col">{t('labels.filled_total')}</th>
                  <th scope="col">{t('labels.order_price')}</th>
                  <th scope="col">{t('labels.entry_price')}</th>
                  <th scope="col">{t('labels.trigger_price')}</th>
                  <th scope="col">{t('labels.liq_price')}</th>
                  <th scope="col">{t('labels.exit_price')}</th>
                  <th scope="col">{t('labels.realized_pnl')}</th>
                  <th scope="col">{t('labels.trade_type')}</th>
                  <th scope="col">{t('labels.order_type')}</th>
                  <th scope="col">{t('labels.status')}</th>
                </tr>
              </thead>
              <tbody>
                <td colSpan="10">
                  {token ? (
                    `${t('labels.empty')}`
                  ) : (
                    <div className="graph-table-btns buy-tabs">
                      <Link to="/register">
                        <button
                          type="button"
                          className="mb-2 register-now"
                        >
                          {t('labels.register_now')}
                        </button>
                      </Link>
                      <Link to="/login">
                        <button type="button" className="login-now">
                          {t('labels.login')}
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
              </tbody>
            </Table>
          )}
        </Tab>
        <Tab eventKey="assets" title={t('labels.assets')}>
          {amounts && amounts.length ? (
            <DataTable
              columns={accountColumns}
              data={amounts?.sort((a, b) => b.amount - a.amount).filter((coin) => currencyData?.find((cur) => cur._id === coin.currencyId))}
              pagination
              fixedHeader
              persistTableHead
              theme="solarizedd"
              noDataComponent={t('labels.no_records')}
              paginationComponentOptions={{
                rowsPerPageText: t('pagination.rows_per_page'),
                rangeSeparatorText: t('pagination.range_separator'),
              }}
            />
          ) : (
            <Table responsive className="empty-table">
              <thead>
                <tr>
                  <th scope="col">{t('labels.symbol')}</th>
                  <th scope="col">{t('labels.coin')}</th>
                  <th scope="col">{t('portfolio.spot_wallet_amount')}</th>
                  <th scope="col">{t('portfolio.futures_wallet_amount')}</th>
                </tr>
              </thead>
              <tbody>
                <td colSpan="4">
                  {token ? (
                    `${t('labels.empty')}`
                  ) : (
                    <div className="graph-table-btns buy-tabs">
                      <Link to="/register">
                        <button
                          type="button"
                          className="mb-2 register-now"
                        >
                          {t('labels.register_now')}
                        </button>
                      </Link>
                      <Link to="/login">
                        <button type="button" className="login-now">
                          {t('labels.login')}
                        </button>
                      </Link>
                    </div>
                  )}
                </td>
              </tbody>
            </Table>
          )}
        </Tab>
      </Tabs>

      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTPSL} centered onHide={handleCloseTPSL}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span className="text-white">{t('labels.tpsl')}</span></div>

            <div className="d-flex justify-content-between mb-2">
              <span className="text-white">{t('labels.entry_price')}</span>
              <span className="text-white">{toFixed(selectedRow.tradeStartPrice, 2)}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span className="text-white">{t('labels.liq_price')}</span>
              <span className="text-green">{toFixed(selectedRow.tradeEndPrice, 2)}</span>
            </div>

            <div className="take-profit-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span className="text-white">{`${t('labels.take_profit')} ${selectedRow?.fromCurrency?.symbol}`}</span>
                <span className="text-white">{t('labels.last_traded_price')}</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={TP}
                  onChange={(e) => setTP(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>{t('labels.take_profit')}</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span className="text-white">
                  {t('labels.stop_loss')}
                  {' '}
                  {selectedRow?.fromCurrency?.symbol}
                </span>
                <span className="text-white">{t('labels.last_traded_price')}</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={SL}
                  onChange={(e) => setSL(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>{t('labels.stop_loss')}</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <div className="limit-modal-btns">
            <button type="button" onClick={() => updateTPSL()} className="btn confirm" disabled={!rates?.length}>{t('labels.confirm')}</button>
            <button type="button" onClick={handleCloseTPSL} className="btn cancel">{t('labels.cancel')}</button>
          </div>

        </Modal.Body>
      </Modal>

      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTrailing} centered onHide={handleCloseTrailing}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span className="text-white">{t('labels.trailing_stop')}</span></div>

            <div className="d-flex justify-content-between mb-2">
              <span className="text-white">{t('labels.entry_price')}</span>
              <span className="text-white">{toFixed(selectedRow.tradeStartPrice, 2)}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span className="text-white">{t('labels.liq_price')}</span>
              <span className="text-green">{toFixed(selectedRow.tradeEndPrice, 2)}</span>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span className="text-white">
                  {`${t('labels.trailing_stop')} ${selectedRow?.fromCurrency?.symbol}`}
                </span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder={t('labels.trailing_stop')}
                  min="0"
                  value={trailingPrice}
                  onChange={(e) => setTrailingPrice(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <div className="limit-modal-btns">
            <button type="button" onClick={() => updateTrailing()} className="btn confirm">{t('labels.confirm')}</button>
            <button type="button" onClick={handleCloseTrailing} className="btn cancel">{t('labels.cancel')}</button>
          </div>

        </Modal.Body>
      </Modal>

      {isTradingEnabled && (
        <Modal className="withdrawal-modal limit-modal market-modal" show={showMarket} centered onHide={handleCloseMarket}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="buy-tabs">
              <div className="mb-4"><span className="text-white">{t('labels.market_close')}</span></div>

              <span className="text-white">
                {`${t('labels.order_by_qty')} ${selectedRow?.toCurrency?.symbol}`}
              </span>

              <div className="d-flex mb-4">
                <p className="order-qty-value">
                  {coinAmount}
                </p>

                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.toCurrency?.symbol}
                </InputGroup.Text>
              </div>
            </div>
            <div className="limit-modal-btns">
              <button type="button" onClick={updateMarket} className="btn confirm">{t('labels.confirm')}</button>
              <button type="button" onClick={handleCloseMarket} className="btn cancel">{t('labels.cancel')}</button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isTradingEnabled && (
        <Modal className="withdraw-details two-factor-auth text-center" centered backdrop="static" show={show} onHide={handleClose}>
          <Modal.Header className="modal-main-heading" closeButton />
          <Modal.Body className="text-white">
            <h5 className="mb-5">
              {t('messages.stop_rate_confirmation', { stop_rate: `${toFixed(stopRate, 2)}` })}
            </h5>
            <div className="limit-modal-btns">
              <button type="button" onClick={handleClose} className="btn cancel">{t('labels.no')}</button>
              <button type="button" onClick={() => { handleStop(); }} className="btn confirm">{t('labels.yes')}</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default TradeOrdersDatatables;
